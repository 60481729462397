import styled from 'styled-components';
import { Card as CardMUI } from '@mui/material';
import { colors } from '../../theme/styles';

export const Card = styled(CardMUI)`
  margin-bottom: 1rem;
  && {
    background-color: ${colors?.backgroundWhite};
  }

  .MuiCardContent-root {
    margin-bottom: 1rem;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors?.theme?.primary};
  padding-bottom: 0.5rem;
`;
