import React from 'react';
import { Card } from './styled';
import { Grid } from '@mui/material';

function CardLayout({ desktopSize, mobileSize, width, children }) {
  return (
    <Card $desktop={desktopSize} $mobile={mobileSize} $width={width}>
      <Grid container>{children}</Grid>
    </Card>
  );
}

export default CardLayout;
