import { Button, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Container } from './styled';
import {
  AlertMessage,
  Loading,
  Modal,
  TextFieldKeyPress,
  UploadButton
} from '../../../../../../components';
import { useForm } from '../../../../../../hooks/useForm';
import { GENERIC_ERROR } from '../../../../../../utils/constantes';
import {
  editContractStep1,
  editContractStep2,
  editContractStep3
} from '../../../../../../services/contractService';
import { clearAlertMessage, clearPercent, toCent } from '../../../../../../utils';
import { createCoinQuotation, findCoin } from '../../../../../../services/coinService';
import { Values, CoinName } from '../../../../../../utils/enums';
import { InformativeText } from '../../../../../../components/InformativeText';

export function Final({ onBackStep, setForm, allForm, lastStep, setLastStep }) {
  const initialForm = {
    is_confidential: false,
    is_signed: false,
    file: '',
    observation: ''
  };
  const [final, onChange, _, setFinal] = useForm(initialForm);
  const [document, setDocument] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const handleOpen = () => {
    onCloseAlertMessage();
    setForm((prevForm) => ({
      ...prevForm,
      ...{ ...final, file: document }
    }));
    setOpenModal(true);
  };

  const onCloseAlertMessage = () => {
    setAlertMessage({
      severity: '',
      title: '',
      message: ''
    });
  };
  const [alertMessage, setAlertMessage] = useState({
    severity: '',
    title: '',
    message: ''
  });

  useEffect(() => {
    getExistForm();

    setDocument(allForm.file);
    setDocumentName('PRINCIPAL.pdf');
  }, [allForm]);

  const onConfirmModal = async () => {
    handleClose();
    await createContract();
  };

  const createContract = async () => {
    try {
      setLoading(true);
      let actualStep = lastStep;

      // Etapa 1 - Criar as informações básicas do contrato
      if (actualStep === 1) {
        await saveBasicInformations();

        actualStep = 2;
        setLastStep(2);
      }

      // Etapa 2 - Criar as informações financeiras do contrato
      if (actualStep === 2) {
        await saveFinanialInformations();

        actualStep = 3;
        setLastStep(3);
      }

      // Etapa 3 - Salvar documento em PDF
      if (actualStep === 3) {
        await saveDocument();

        actualStep = 1;
        setLastStep(1);
      }

      setLoading(false);
      setAlertMessage((prev) => ({
        ...prev,
        title: 'Contrato editado com sucesso.',
        severity: 'success'
      }));
    } catch (err) {
      setAlertMessage({
        title: err?.trace,
        message: err?.message || GENERIC_ERROR
      });
      setLoading(false);
    }
  };

  const saveBasicInformations = async () => {
    const {
      title,
      service,
      uuid_contract: contractUUID,
      uuid_sector: uuidSector,
      uuid_segment: uuidSegment,
      uuid_index: uuidIndex,
      uuid_history_index: uuidHistoryIndex,
      init_date: initDate,
      expiration_date: expirationDate,
      uuid_provider: uuidProvider,
      is_confidential: isConfidential,
      observation,
      service_order_number: serviceOrder
    } = allForm;

    const uuidCities = allForm?.cities.map((city) => city.uuid_city);

    await editContractStep1(
      contractUUID,
      title,
      service,
      uuidSector,
      uuidSegment,
      uuidIndex !== Values.NO_INDEX ? uuidIndex : null,
      uuidHistoryIndex,
      initDate,
      expirationDate,
      uuidProvider,
      isConfidential,
      uuidCities,
      observation,
      serviceOrder
    );
  };

  const saveFinanialInformations = async () => {
    const {
      uuid_coin: uuidCoin,
      is_custom_coin: isCustomCoin,
      exchang_type: exchangType
    } = allForm;

    const exchangeValue = allForm.exchang_value && toCent(allForm.exchang_value);

    if (isCustomCoin) {
      const coinResult = await findCoin(uuidCoin);

      if (coinResult?.title !== CoinName.REAL) {
        const result = await createCoinQuotation(uuidCoin, exchangeValue, exchangType);
        allForm['uuid_coin_history'] = result.uuid;
      }
    }

    const {
      debit,
      credit,
      uuid_contract: uuidContract,
      readjustment_month: readjustmentMonth,
      total_installments: totalInstallments,
      installments_paid: installmentsPaid,
      uuid_coin_history: uuidCoinHistory,
      payment_periodicity: paymentPeriodicity,
      readjustment_periodicity: readjustmentPeriodicity,
      days_rescission_fine: daysRescissionFine
    } = allForm;

    const initialValue = allForm.initial_value && toCent(allForm.initial_value);
    const lateFee = clearPercent(allForm.late_fee);
    const fineForSalary = clearPercent(allForm.fine_for_salary);
    const lateInterest = clearPercent(allForm.late_interest);

    await editContractStep2(
      uuidContract,
      debit,
      credit,
      readjustmentMonth,
      totalInstallments,
      installmentsPaid,
      uuidCoin,
      uuidCoinHistory,
      paymentPeriodicity,
      readjustmentPeriodicity,
      initialValue,
      lateFee,
      daysRescissionFine,
      fineForSalary,
      lateInterest
    );
  };

  const saveDocument = async () => {
    const { uuid_contract: uuidContract } = allForm;
    await editContractStep3(uuidContract, document);
  };

  const getExistForm = () => {
    // Se já tiver informações no formulario principal ele é preenchido novamente
    for (const key in final) {
      setFinal((prevValue) => ({
        ...prevValue,
        [key]: (allForm[key] && allForm[key]) || ''
      }));
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;

    const target = {
      name,
      value: checked
    };
    onChange({ target });
  };

  const backStep = () => {
    setForm((prevForm) => ({
      ...prevForm,
      ...{ ...final, file: document, file_name: documentName }
    }));
    onBackStep();
  };

  return (
    <Container container>
      <Loading loading={loading} />
      <Grid item xs={12}>
        <AlertMessage
          title={alertMessage.title}
          severity={alertMessage.severity}
          message={alertMessage.message}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>
      <UploadButton
        text="Contrato em PDF *"
        noImageMassege="Nunhum documento selecionado."
        setImage={setDocument}
        outImageName={documentName}
        setOutImageName={setDocumentName}
        noConvertToBase64
      />
      <FormGroup id="checkbox">
        <FormControlLabel
          control={
            <Checkbox
              name="is_confidential"
              checked={final.is_confidential}
              onChange={handleChange}
            />
          }
          label="Ao marcar essa opção, você diz que o contrato é confidencial."
        />
        <FormControlLabel
          control={<Checkbox name="is_signed" checked={final.is_signed} onChange={handleChange} />}
          required
          label="Confirmo que o contrato foi assinado pelo jurídico e pelas partes. *"
        />
      </FormGroup>
      <TextFieldKeyPress
        multiline
        rows={4}
        label="Observação"
        name="observation"
        onChange={onChange}
        value={final.observation}
      />

      <InformativeText />

      <Grid item className="buttonGroup twoButtons">
        <Button onClick={backStep} variant="outlined">
          Voltar
        </Button>
        <Button onClick={handleOpen} disabled={!final.is_signed}>
          Editar
        </Button>
      </Grid>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title="Editar contrato"
        text="Tem certeza que deseja editar o contrato?"
      />
    </Container>
  );
}
