import styled from 'styled-components';
import { breakpoints } from '../../../../../../../../../theme/breakpoints';

export const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (max-width: ${breakpoints.md}) {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }
`;
