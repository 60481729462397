import {
  MobileTableConteiner,
  PaginationMobile,
  SkeletonMobile,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle,
  TargetStatus
} from '../../../../../../components';
import { goToCompanyInformation } from '../../../../../../router/coordinator';
import { Status } from '../../../../../../utils/enums';

export function MobileTable({ companies, navigate, loading, count, page, offset, handlePage }) {
  const conmpaniesList = companies?.map((company) => {
    return (
      <TableMobileCell key={company?.uuid}>
        <TableMobileItem>
          <TableMobileTitle>Nome </TableMobileTitle>
          <TableMobileContent>{company?.name?.toTitleCase()}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Documento </TableMobileTitle>
          <TableMobileContent>{company?.document?.toMask('document')}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Sigla </TableMobileTitle>
          <TableMobileContent>{company?.acronym?.toUpperCase()}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Status </TableMobileTitle>
          <TableMobileContent>
            <TargetStatus status={company?.active ? Status.ACTIVE : Status.INACTIVE} />
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Setor de atuação </TableMobileTitle>
          <TableMobileContent>{company?.industry?.title.toTitleCase() || '-'}</TableMobileContent>
        </TableMobileItem>

        <TableMobileButton onClick={() => goToCompanyInformation(navigate, company?.uuid)}>
          Ver detalhes
        </TableMobileButton>
      </TableMobileCell>
    );
  });

  return (
    <MobileTableConteiner>
      {!loading ? conmpaniesList : <SkeletonMobile numbersOfColumns={5} />}
      <PaginationMobile count={count} page={page} rowsPerPage={offset} handlePage={handlePage} />
    </MobileTableConteiner>
  );
}
