import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import CalculateIcon from '@mui/icons-material/Calculate';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { BasePage } from '../../../../components/BaseMain';
import {
  AlertMessage,
  ButtonPage,
  HiddenForPermission,
  Loading,
  TargetStatus,
  TopBackArea
} from '../../../../components';
import {
  goToBack,
  goToCompanyInformation,
  goToDatailsFinancialContract,
  goToEditContract,
  goToListAdicionalsContract,
  goToListAllContracts,
  goToVisualizeContract
} from '../../../../router/coordinator';
import { ButtonGroup, OptionButton, Title } from './styled';
import { useCallback, useEffect, useState } from 'react';
import { Status } from '../../../../utils/enums';
import {
  activeOrInactiveContract,
  deleteContract,
  findContract
} from '../../../../services/contractService';
import { GENERIC_ERROR } from '../../../../utils/constantes';
import { ModalInput } from '../../../../components/ModalInput';
import { Edit } from '@mui/icons-material';
import MenuOptions from '../../../../components/MenuOptions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import ResizedLayout from '../../../../components/ResizedLayout';

export function DetailsContractPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [openModal, setOpenModal] = useState(false);
  const [contract, setContract] = useState({});
  const [loading, setLoading] = useState(true);
  const [contractActive, setContractActive] = useState();
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error',
    progressDuration: undefined,
    onCompleteProgress: undefined
  });

  const breadcrumbs = [
    { title: 'Contratos', functionCoordinator: () => goToListAllContracts(navigate) },
    { title: 'Detalhes' }
  ];

  const handleModal = () => setOpenModal(!openModal);
  const onConfirmModal = async (exclusionReason) => {
    try {
      setLoading(true);
      handleModal();

      await deleteContract(uuid, exclusionReason);
      setLoading(false);
      setAlertMessage((prev) => ({
        ...prev,
        title: 'Contrato deletado com sucesso. Você será redirecionado a tela anterior.',
        severity: 'success'
      }));
      setTimeout(() => goToListAllContracts(navigate), 2000);
    } catch (err) {
      setAlertMessage({
        title: err.message || GENERIC_ERROR,
        alertMessage: err.trace && `Código: ${err.trace}`
      });
    } finally {
      setLoading(false);
    }
  };

  const getContract = useCallback(async () => {
    try {
      const result = await findContract(uuid);
      setContract(result);
      setContractActive(result.active);
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        alertMessage: error?.trace && `Código: ${error?.trace}`
      });
    } finally {
      setLoading(false);
    }
  }, [contractActive]);

  useEffect(() => {
    getContract();
  }, [getContract]);

  const activeOrInactive = async () => {
    try {
      setLoading(true);

      await activeOrInactiveContract(uuid);

      setContractActive(!contractActive);
      setAlertMessage({
        title: `Contrato ${contract.active ? 'inativado' : 'ativado'} com sucesso.`,
        severity: 'success'
      });
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        alertMessage: error?.trace && `Código: ${error?.trace}`
      });
    } finally {
      setLoading(false);
    }
  };

  function RightButton() {
    return (
      <MenuOptions.Container>
        <HiddenForPermission keys="ACTIVE_INACTIVE_CONTRACT">
          <MenuOptions.Item onClick={activeOrInactive}>
            <OptionButton variant="text" color={contractActive ? 'error' : 'success'}>
              {contractActive ? (
                <>
                  <DoNotDisturbAltIcon />
                  <span>Inativar contrato</span>
                </>
              ) : (
                <>
                  <CheckCircleIcon />
                  <span>Ativar contrato</span>
                </>
              )}
            </OptionButton>
          </MenuOptions.Item>
        </HiddenForPermission>
        <HiddenForPermission keys="DELETE_CONTRACT">
          <MenuOptions.Item onClick={handleModal}>
            {contract.status && contract.status !== Status.deleted && (
              <OptionButton variant="text" color="error">
                <DeleteIcon />
                <span>Deletar contrato</span>
              </OptionButton>
            )}
          </MenuOptions.Item>
        </HiddenForPermission>
      </MenuOptions.Container>
    );
  }

  const handleCityServiceProvisionCity = (city) => {
    if (!city) {
      return '-';
    }

    return `${city?.name?.toCapitalizeCase?.()} - ${city?.state?.name?.toCapitalizeCase?.()}/${city?.state?.uf?.toUpperCase?.()}`;
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TopBackArea onBack={() => goToBack(navigate)} rightArea={<RightButton />} />
      <Loading loading={loading} />
      <Grid container>
        <AlertMessage
          title={alertMessage.title}
          message={alertMessage.message}
          severity={alertMessage.severity}
          progressDuration={alertMessage.progressDuration}
          onCompleteProgress={alertMessage.onCompleteProgress}
        />
      </Grid>

      <ResizedLayout.Container>
        <ResizedLayout.Card desktopSize="small" mobileSize="large" width={4}>
          <ResizedLayout.Title>Informações básicas</ResizedLayout.Title>
          <ResizedLayout.Item lg={10}>
            <p>Título</p>
            <p>
              <Title>
                {contract.title?.toTitleCase() || '-'}
                <span>
                  <TargetStatus status={contract.status} variant="solid" />
                </span>
              </Title>
            </p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={2}>
            <p>Número da OS</p>
            <p>{contract?.serviceOrder?.osNumber || '-'}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={3}>
            <p>Identificador</p>
            <p>{contract.id || '-'}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={4}>
            <p>Segmento</p>
            <p>{contract?.segments?.title?.toTitleCase() || '-'}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={3}>
            <p>Setor</p>
            <p>{contract?.sector?.title?.toTitleCase() || '-'}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={2}>
            <p>É confidencial?</p>
            <p>{contract.isConfidential ? 'Sim' : 'Não'}</p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
        <ResizedLayout.Card desktopSize="small" mobileSize="small" width={2}>
          <ResizedLayout.Title
            onLink={() => goToCompanyInformation(navigate, contract?.provider?.uuid, true)}
          >
            Fornecedor
          </ResizedLayout.Title>
          <ResizedLayout.Item>
            <p>Nome</p>
            <p>{contract?.provider?.name?.toTitleCase() || '-'}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item>
            <p>CPF ou CNPJ</p>
            <p>{contract?.provider?.document?.toMask('document') || '-'}</p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
        <ResizedLayout.Card desktopSize="small" mobileSize="small" width={1}>
          <ResizedLayout.Title>Índice</ResizedLayout.Title>
          <ResizedLayout.Item>
            <p>Inicial</p>
            <p>{contract?.indexInitial?.name?.toUpperCase() || '-'}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item>
            <p>Atual</p>
            <p>{contract?.indexCurrent?.name?.toUpperCase() || '-'}</p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
        <ResizedLayout.Card desktopSize="small" mobileSize="medium" width={2}>
          <ResizedLayout.Title>Locais de pretação de serviço</ResizedLayout.Title>
          <ResizedLayout.Item>
            <p>Cidades</p>
            <p>
              {contract?.cities?.length
                ? contract?.cities?.map((city) => (
                    <div key={city?.uuid}> {handleCityServiceProvisionCity(city)}</div>
                  ))
                : '-'}
            </p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
        <ResizedLayout.Card desktopSize="small" mobileSize="small" width={1}>
          <ResizedLayout.Title>Datas</ResizedLayout.Title>
          <ResizedLayout.Item>
            <p>De início</p>
            <p>{contract.initDate?.toMask('date') || '-'}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item>
            <p>De expiração</p>
            <p>
              {!contract.expirationDate
                ? 'Indeterminado'
                : contract.expirationDate?.toMask('date') || '-'}
            </p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
        <ResizedLayout.Card desktopSize="small" mobileSize="small" width={2}>
          <ResizedLayout.Title>Informações dos contratos adicionais</ResizedLayout.Title>
          <ResizedLayout.Item lg={8}>
            <p>Data do aditivo</p>
            <p>{contract?.additionalDate?.toMask('date') || '-'}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={4}>
            <p>Data do distrato</p>
            <p>{contract?.cancellationDate?.toMask('date') || '-'}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item>
            <p>Número de contratos adicionais</p>
            <p>{contract?.additionalContracts?.length || 0}</p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
        <ResizedLayout.Card desktopSize="small" mobileSize="small" width={6}>
          <ResizedLayout.Title>Outras informações</ResizedLayout.Title>
          <ResizedLayout.Item>
            <p>Observação</p>
            <p>{contract.observation || '-'}</p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
      </ResizedLayout.Container>
      <ButtonGroup>
        <HiddenForPermission keys="FIND_FINANCIAL_CONTRACT">
          <ButtonPage
            Icon={CalculateIcon}
            onClick={() => goToDatailsFinancialContract(navigate, uuid)}
          >
            Detalhes financeiros
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys={['LIST_ADDITIONALS_CONTRACT', 'CREATE_ADDITIONAL_CONTRACT']}>
          <ButtonPage
            Icon={AddToPhotosIcon}
            onClick={() => goToListAdicionalsContract(navigate, uuid)}
          >
            Contratos adicionais
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys="FIND_FILE_CONTRACT">
          <ButtonPage
            Icon={InsertDriveFileIcon}
            onClick={() => goToVisualizeContract(navigate, uuid)}
          >
            Visualizar contrato
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys="EDIT_CONTRACT">
          <ButtonPage Icon={Edit} onClick={() => goToEditContract(navigate, uuid)}>
            Editar contrato
          </ButtonPage>
        </HiddenForPermission>
      </ButtonGroup>

      <ModalInput
        open={openModal}
        onClose={handleModal}
        onConfirm={onConfirmModal}
        title="Deletar contrato"
        inputLabel={`Digite o motivo da exclusão do contrato: ${contract.id}`}
        buttonConfirm="Deletar"
        buttonCancel="Cancelar"
        warning="Esta ação é irreversível."
      />
    </BasePage>
  );
}
