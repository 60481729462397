import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DatePicker } from '@mui/x-date-pickers';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { BasePage } from '../../../../components/BaseMain';
import {
  AlertMessage,
  EmptyTable,
  HiddenForPermission,
  Loading,
  Modal,
  TargetStatus,
  TitlePages
} from '../../../../components';
import {
  goToAddAdicionalsContract,
  goToBack,
  goToDatailsContract,
  goToListAllContracts
} from '../../../../router/coordinator';
import { FilterContainer } from '../../../../components/FilterContainer';
import { useForm } from '../../../../hooks/useForm';
import { Table } from './styled';
import { clearAlertMessage, hasPermission, isDesktop, onChangeDate } from '../../../../utils';
import { DesktopTable } from './TableComponents/DesktopTable';
import { MobileTable } from './TableComponents/MobileTable';
import {
  deleteAdditionalContract,
  findContract,
  listAdditionalsContracts,
  listContractTypes
} from '../../../../services/contractService';
import { GENERIC_ERROR, INVALID_DATE } from '../../../../utils/constantes';
import { Order, Status } from '../../../../utils/enums';
import { FilterButton } from '../../../../components/FilterButton';
import { useUpdateState } from '../../../../hooks/useUpdateState';

export function ListAditionalsContractsPage() {
  const navigate = useNavigate();

  const [{ count, page, offset, order, orderBy }, setPaginate] = useUpdateState({
    count: 0,
    page: 0,
    offset: 5,
    order: Order.DESC,
    orderBy: ''
  });

  const [
    {
      additionalsContracts,
      contract,
      contractsType,
      loading,
      contractIsActive,
      openModal,
      selectedAdditionalContract
    },
    setStates
  ] = useUpdateState({
    additionalsContracts: [],
    contract: [],
    contractsType: [],
    loading: true,
    contractIsActive: false,
    openModal: false,
    selectedAdditionalContract: undefined
  });

  const [alertMessage, setAlertMessage] = useUpdateState({
    title: '',
    message: '',
    severity: 'error'
  });

  const [filters, onChange, clearFilter] = useForm({
    uuid_type: '',
    init_date: null,
    expiring_date: null
  });
  const [debouncedFilter] = useDebounce(filters, 500);

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const handleModalOpen = (uuidAdditionalContract) =>
    setStates({ openModal: true, selectedAdditionalContract: uuidAdditionalContract });

  const handleModalClose = () =>
    setStates({ openModal: false, selectedAdditionalContract: undefined });

  const onConfirmModal = useCallback(async () => {
    try {
      setStates({ loading: true });
      handleModalClose();

      await deleteAdditionalContract(selectedAdditionalContract);

      const newAdditionalsContracts = structuredClone(additionalsContracts)?.filter(
        (additionalContract) => additionalContract?.uuid !== selectedAdditionalContract
      );

      setStates({ additionalsContracts: newAdditionalsContracts });

      setAlertMessage({
        title: 'Contrato adicional deletado com sucesso.',
        severity: 'success'
      });
    } catch (err) {
      setAlertMessage({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace && `Código: ${err.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  }, [selectedAdditionalContract, additionalsContracts]);

  const init = useCallback(async () => {
    try {
      const [contract, contractsType] = await Promise.all([
        findContract(uuid),
        listContractTypes()
      ]);

      const contractIsActive = contract?.status === Status.ACTIVE.toUpperCase();
      setStates({
        contract,
        contractsType,
        contractIsActive
      });

      if (!contractIsActive) {
        setAlertMessage({
          title: `Não é possível cadastrar contratos adicionais enquanto o contrato principal não estiver com o status "Ativo". O status atual do contrato principal é "${contract?.status?.toTitleCase()}"`,
          message: '',
          severity: 'warning'
        });
      }
    } catch (err) {
      setAlertMessage({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace && `Código: ${err.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  }, []);

  const listAllContracts = useCallback(async () => {
    try {
      setStates({ loading: true });

      const filter = handleFilter();
      const result = await listAdditionalsContracts(uuid, page, offset, order, filter);

      setStates({ additionalsContracts: result.data });
      setPaginate({ count: result.count });
    } catch (err) {
      setAlertMessage({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace && `Código: ${err.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  }, [page, offset, order, debouncedFilter]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    listAllContracts();
  }, [listAllContracts]);

  const handleFilter = () => {
    const initDateFormat = filters.init_date && filters.init_date.format('YYYY-MM-DD');
    const expiringDateFormat = filters.expiring_date && filters.expiring_date.format('YYYY-MM-DD');

    const handleFitler = {
      ...filters,
      order_by: orderBy,
      init_date: initDateFormat === INVALID_DATE ? undefined : initDateFormat,
      expiring_date: expiringDateFormat === INVALID_DATE ? undefined : expiringDateFormat
    };

    return handleFitler;
  };

  const handlePage = (_event, newPage) => {
    setPaginate({ page: newPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginate({ offset: parseInt(event?.target?.value, 10), page: 0 });
  };
  const handleOrder = (field) => {
    setPaginate({ orderBy: field, order: order === Order.ASC ? Order.DESC : Order.ASC });
  };

  function TableResponsive() {
    if (!additionalsContracts?.length && !loading) {
      return <EmptyTable />;
    }

    if (isDesktop()) {
      return (
        <DesktopTable
          uuidContract={contract.uuid}
          contracts={additionalsContracts}
          navigate={navigate}
          loading={loading}
          count={count}
          page={page}
          offset={offset}
          order={order}
          orderBy={orderBy}
          handlePage={handlePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOrder={handleOrder}
          handleModal={handleModalOpen}
        />
      );
    }

    return (
      <MobileTable
        contracts={additionalsContracts}
        navigate={navigate}
        loading={loading}
        count={count}
        page={page}
        offset={offset}
        handlePage={handlePage}
        handleModal={handleModalOpen}
      />
    );
  }

  const breadcrumbs = [
    { title: 'Contratos', functionCoordinator: () => goToListAllContracts(navigate) },
    { title: 'Detalhes', functionCoordinator: () => goToDatailsContract(navigate, contract.uuid) },
    { title: 'Adicionais' }
  ];

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <Loading loading={loading} />
      <TitlePages
        title={`Contratos adicionais do ${contract.id || contract.title?.toTitleCase?.()}`}
        description="Aqui estão os contratos adicionais, que podem incluir aditivos, distratos ou substituições."
        textButton="Cadastrar adicional"
        Icon={AddCircleOutlineIcon}
        onClickButton={() => goToAddAdicionalsContract(navigate, contract.uuid)}
        keyPermissionButton="CREATE_ADDITIONAL_CONTRACT"
        toHideDivider={!hasPermission('LIST_ADDITIONALS_CONTRACT')}
        disabledButton={!contractIsActive}
      />

      <HiddenForPermission keys="LIST_ADDITIONALS_CONTRACT">
        <Grid container>
          <AlertMessage
            title={alertMessage?.title}
            message={alertMessage?.message}
            severity={alertMessage?.severity}
            onClose={() => clearAlertMessage(setAlertMessage)}
          />
        </Grid>
        <FilterContainer>
          <Grid item xs={12} lg={3}>
            <TextField
              select
              label="Tipo"
              name="uuid_type"
              value={filters.uuid_type}
              onChange={onChange}
            >
              {contractsType?.map((c) => (
                <MenuItem key={c.uuid} value={c.uuid}>
                  <TargetStatus status={c.title} noCircle padding="0" />
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={3}>
            <DatePicker
              label="Data de início"
              name="init_date"
              value={filters.init_date}
              onChange={(e) => onChangeDate(e, 'init_date', onChange)}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <DatePicker
              label="Data de expiração ou distrato"
              name="expiring_date"
              value={filters.expiring_date}
              onChange={(e) => onChangeDate(e, 'expiring_date', onChange)}
            />
          </Grid>
          <Grid item xs={12} lg={1}>
            <FilterButton onClick={clearFilter} />
          </Grid>
        </FilterContainer>

        <Table>
          <TableResponsive />
        </Table>

        <div className="buttonGroup twoButtons">
          <Button variant="outlined" onClick={() => goToDatailsContract(navigate, uuid)}>
            Voltar
          </Button>
          <span> </span>
        </div>
      </HiddenForPermission>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        onConfirm={onConfirmModal}
        title="Deletar contrato adicional"
        text={`Tem certeza que deseja deletar o contrato adicional do ${contract.id}?`}
        buttonConfirm="Deletar"
        buttonCancel="Cancelar"
        warning="Esta ação é irreversível."
      />
    </BasePage>
  );
}
