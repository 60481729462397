import styled from 'styled-components';
import { TextFieldKeyPress } from '../TextFieldKeyPress';

export const StyledTextField = styled(TextFieldKeyPress)({
  '& input[type="password"]::-ms-reveal, & input[type="password"]::-ms-clear': {
    display: 'none'
  },
  '& input[type="password"]::-webkit-textfield-decoration-container': {
    display: 'none'
  },
  '& input[type="password"]': {
    appearance: 'none',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none'
  }
});
