import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { breakpoints } from '../../../../theme/breakpoints';

export const Title = styled.div`
  @media (min-width: ${breakpoints.md}) {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 1rem;
    margin-top: 0.5rem;

    @media (min-width: ${breakpoints.md}) {
      margin-left: 0.5rem;
      margin-top: 0.1rem;
      margin-top: 0;
    }
  }
`;

export const ButtonGroup = styled.div`
  margin: 1rem 0.6rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const ButtonIcon = styled(IconButton)`
  height: 1rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;
