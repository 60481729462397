import { InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { StyledTextField } from './styled';

// Input de senha com o botão de mostrar ou não
export function TextFieldPassword(props) {
  const [showPassword, setPassword] = useState(false);

  return (
    <StyledTextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="start"
            onClick={() => setPassword(!showPassword)}
            sx={{ cursor: 'pointer' }}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </InputAdornment>
        )
      }}
    />
  );
}
