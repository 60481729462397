import { Grid, InputAdornment } from '@mui/material';
import { isDesktop, onChangePercent } from '../../../../../../../../../utils';
import { TextFieldNextFocus, BorderWithText } from '../../../../../../../../../components';
import { Container } from './styled';

export function TrafficTicketArea({ form, onChange }) {
  return (
    <Container>
      <BorderWithText title="Multa por atraso">
        <Grid container spacing={isDesktop() && 2}>
          <Grid item xs={12} lg={6}>
            <TextFieldNextFocus
              label="Porcentagem da multa"
              placeholder="0,01%"
              name="late_fee"
              value={form.late_fee}
              onChange={(e) => onChangePercent(e, onChange)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <b>%</b>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextFieldNextFocus
              label="Juros da multa"
              placeholder="0,01%"
              name="late_interest"
              value={form.late_interest}
              onChange={(e) => onChangePercent(e, onChange)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <b>%</b>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </BorderWithText>
      <BorderWithText title="Multa rescisória">
        <Grid container spacing={isDesktop() && 2}>
          <Grid item xs={12} lg={6}>
            <TextFieldNextFocus
              label="Porcentagem da multa"
              placeholder="0,11%"
              name="fine_for_salary"
              value={form.fine_for_salary}
              onChange={(e) => onChangePercent(e, onChange, '###,##')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <b>%</b>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextFieldNextFocus
              type="number"
              label="Número de dias"
              name="days_rescission_fine"
              value={form.days_rescission_fine}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </BorderWithText>
    </Container>
  );
}
