import { Button } from '@mui/material';
import styled from 'styled-components';
import { breakpoints } from '../../../../theme/breakpoints';

export const OptionButton = styled(Button)`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  span {
    margin-left: 0.5rem;
    padding-top: 4px;
  }
`;

export const Title = styled.div`
  @media (min-width: ${breakpoints.md}) {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 1rem;
    margin-top: 0.5rem;

    @media (min-width: ${breakpoints.md}) {
      margin-left: 0.5rem;
      margin-top: 0.1rem;
      margin-top: 0;
    }
  }
`;

export const ButtonGroup = styled.div`
  margin: 1rem 0.6rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;
