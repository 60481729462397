import { api } from '../utils/axiosConfig';

/**
 * Lista todos os contratos da empresa
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listAllContracts = async (initialPage, offset, order = 'ASC', filter = {}) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get(`/contract/list-all`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca um contrato
 * @param {*} uuid - identificador do contrato
 * @returns
 */
export const findContract = async (uuid) => {
  try {
    const { data } = await api.get(`/contract/find/${uuid}`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca as informações financeiras do contrato
 * @param {*} uuid - identificador do contrato
 * @returns
 */
export const findFinancialContract = async (uuid) => {
  try {
    const { data } = await api.get(`/contract/find-financial/${uuid}`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Deleta um contrato
 * @param {*} uuid - identificador do contrato
 * @param {*} exclusionReason - Motivo da exlusão
 */
export const deleteContract = async (uuid, exclusionReason) => {
  try {
    const data = { exclusion_reason: exclusionReason };
    await api.delete(`/contract/${uuid}`, { data });
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Deleta um contrato adicional
 * @param {*} uuidAdditionalContract - identificador do contrato adicional
 */
export const deleteAdditionalContract = async (uuidAdditionalContract) => {
  try {
    await api.delete(`/contract/additional/${uuidAdditionalContract}`);
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista os contratos adicionais de um contrato
 * @param {*} uuid - identificador do contrato
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param order - ordem [asc, desc]
 * @param filter - filtros
 * @returns
 */
export const listAdditionalsContracts = async (
  uuid,
  initialPage,
  offset,
  order = 'ASC',
  filter = {}
) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get(`/contract/list/additionals/${uuid}`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cria as informações básicas do contrato
 * @param {*} title - título
 * @param {*} service - serviço contratado
 * @param {*} uuidSector - setor que contratou o serviço
 * @param {*} uuidSegment - Segmento
 * @param {*} uuidIndex - indice
 * @param {*} uuidHistoryIndex - valor do indice
 * @param {*} initDate - data inicial do contrato
 * @param {*} expirationDate - data de expiração do contrato
 * @param {*} uuidProvider - Fornecedor
 * @param cityServiceProvision - uuid da cidade da prestação de serviço
 * @param observation - Observação
 * @returns
 */
export const createContractStep1 = async (
  title,
  service,
  uuidSector,
  uuidSegment,
  uuidIndex,
  initDate,
  expirationDate,
  uuidProvider,
  isConfidential,
  uuidCities,
  observation,
  serviceOrder
) => {
  try {
    if (isConfidential !== true && isConfidential !== false) {
      isConfidential = false;
    }

    const payload = {
      title,
      service,
      is_confidential: isConfidential,
      uuid_sector: uuidSector,
      uuid_segment: uuidSegment,
      uuid_index: uuidIndex,
      init_date: initDate,
      expiration_date: expirationDate,
      uuid_provider: uuidProvider,
      uuid_cities: uuidCities,
      observation,
      service_order_number: serviceOrder
    };
    const { data } = await api.post(`/contract/create/step-1`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cadastra as informações financeiras do contrato
 * @param {*} uuidContract - identificador do contrato
 * @param {*} debit - débito
 * @param {*} credit - crédito
 * @param {*} readjustmentMonth - Mês de reajuste do contrato
 * @param {*} totalInstallments - total de parcelas
 * @param {*} installmentsPaid - parcelas pagas
 * @param {*} uuidCoin - moeda usada
 * @param {*} uuidCoinHistory - Cota que foi estabelecida
 * @param {*} expirationPeriodicity - período de expiração
 * @param {*} initialValue - valor inicial
 * @param {*} currentValue - valor atual
 * @param {*} lateFee - Multa por atraso
 * @param {*} daysRescissionFine - Dias pra multa por rescisão de contrato
 * @param {*} fineForSalary - Multa por vencimento
 * @returns
 */
export const createContractStep2 = async (
  uuidContract,
  debit,
  credit,
  readjustmentMonth,
  totalInstallments,
  installmentsPaid,
  uuidCoin,
  uuidCoinHistory,
  paymentPeriodicity,
  readjustmentPeriodicity,
  initialValue,
  lateFee,
  daysRescissionFine,
  fineForSalary,
  lateInterest
) => {
  try {
    const payload = {
      uuid_contract: uuidContract,
      debit,
      credit,
      readjustment_month: readjustmentMonth,
      total_installments: totalInstallments,
      installments_paid: installmentsPaid,
      uuid_coin: uuidCoin,
      uuid_coin_history: uuidCoinHistory,
      payment_periodicity: paymentPeriodicity,
      readjustment_periodicity: readjustmentPeriodicity,
      initial_value: initialValue,
      traffic_ticket: {
        late_fee: lateFee,
        days_rescission_fine: daysRescissionFine,
        fine_for_salary: fineForSalary,
        late_interest: lateInterest
      }
    };
    const { data } = await api.post(`/contract/create/step-2`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cadastra o arquivo do contrato
 * @param {*} uuidContract - identificador do contrato
 * @param {*} file - arquivo do contrato em blob
 * @returns
 */
export const createContractStep3 = async (uuidContract, file) => {
  try {
    const payload = new FormData();
    payload.append('uuid_contract', uuidContract);
    payload.append('file', file);

    const { data } = await api.post(`/contract/create/step-3`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * List os tipos de um contrato
 */
export const listContractTypes = async () => {
  try {
    const { data } = await api.get(`/contract/types/list-all`);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Cria um adicional pro contrato
 * @param {*} uuidContract - identificador do contrato
 * @param {*} uuidContractType - tipo do adicional
 * @param {*} newAmount - novo valor acordado
 * @param {*} initDate - data de inicio do adidicional
 * @param {*} expirationDate - data de expiração
 * @param {*} file - arquivo
 * @param {*} observation - observação
 * @returns
 */
export const createAdditionalContract = async (
  uuidContract,
  uuidContractType,
  newAmount,
  uuidIndex,
  uuidHistoryIndex,
  uuidCoin,
  uuidCoinHistory,
  initDate,
  expirationDate,
  file,
  observation,
  serviceOrder
) => {
  try {
    const payload = new FormData();
    payload.append('uuid_contract_type', uuidContractType);
    payload.append('new_amount', newAmount);
    payload.append('init_date', initDate ? initDate : '');
    payload.append('expiration_date', expirationDate ? expirationDate : '');
    payload.append('uuid_index', uuidIndex);
    payload.append('uuid_history_index', uuidHistoryIndex);
    payload.append('uuid_coin', uuidCoin);
    payload.append('uuid_history_coin', uuidCoinHistory);
    payload.append('file', file);
    payload.append('observation', observation);
    payload.append('service_order_number', serviceOrder);

    const { data } = await api.post(`/contract/create/additional/${uuidContract}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca contrato adicional
 * @param {*} uuidAdditionalContract - identificador do contrato adicional
 * @returns
 */
export const findAdditionalContract = async (uuidAdditionalContract) => {
  try {
    const { data } = await api.get(`/contract/find/additional/${uuidAdditionalContract}`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Edita um adicional do contrato
 * @param {*} uuidAdditionalContractTypes - tipo do adicional
 * @param {*} newAmount - novo valor acordado
 * @param {*} initDate - data de inicio do adidicional
 * @param {*} expirationDate - data de expiração
 * @param {*} file - arquivo
 * @param {*} observation - observação
 * @returns
 */
export const editAdditionalContract = async (
  uuidAdditionalContract,
  uuidAdditionalContractTypes,
  newAmount,
  uuidCoin,
  uuidCoinHistory,
  initDate,
  expirationDate,
  uuidIndex,
  file,
  observation,
  serviceOrder
) => {
  try {
    const payload = new FormData();
    payload.append('uuid_additional_contract_type', uuidAdditionalContractTypes);
    payload.append('file', file);
    uuidCoin && payload.append('uuid_coin', uuidCoin);
    newAmount && payload.append('new_amount', newAmount);
    initDate && payload.append('init_date', initDate);
    uuidCoinHistory && payload.append('uuid_history_coin', uuidCoinHistory);
    expirationDate && payload.append('expiration_date', expirationDate);
    observation && payload.append('observation', observation);
    uuidIndex && payload.append('uuid_index', uuidIndex);
    serviceOrder && payload.append('service_order_number', serviceOrder);

    const { data } = await api.put(`/contract/edit/additional/${uuidAdditionalContract}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca arquivo do contrato
 * @param {*} uuidContract - identificador do contrato
 * @returns
 */
export const findContractFile = async (uuidContract) => {
  try {
    const { data } = await api.get(`/contract/find-file/${uuidContract}`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca arquivo do contrato adicional
 * @param {*} uuidAdditionalContract - identificador do contrato adicional
 * @returns
 */
export const findAdditionalContractFile = async (uuidAdditionalContract) => {
  try {
    const { data } = await api.get(`/contract/find-file/additional/${uuidAdditionalContract}`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista os contratos da empresa que estão a 30 dias de vencer
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listExpiringContracts = async (initialPage, offset, order = 'ASC', filter = {}) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get(`/contract/list/30-days/expiring`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista os contratos da empresa que entraram na multa rescisória
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listTerminationFineContracts = async (
  initialPage,
  offset,
  order = 'ASC',
  filter = {}
) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get(`/contract/list/termination-fine`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista os contratos da empresa que foram adicioandos em 30 dias
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listAddedContracts = async (initialPage, offset, order = 'ASC', filter = {}) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get(`/contract/list/30-days/added`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Busca as informações dos contratos adicionados
 * @returns
 */
export const findAddedContractInformations = async () => {
  try {
    const { data } = await api.get(`/contract/additionals/informations`);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista a parte financeira do contrato
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listFinancialContracts = async (initialPage, offset, order = 'ASC', filter = {}) => {
  try {
    const page = initialPage + 1;
    const { data } = await api.get(`/financial-contract/list`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Edita as informações básicas do contrato
 * @param {*} uuidContract - indentificador do contrato
 * @param {*} title - título
 * @param {*} service - serviço contratado
 * @param {*} uuidSector - setor que contratou o serviço
 * @param {*} uuidSegment - Segmento
 * @param {*} uuidIndex - indice
 * @param {*} uuidHistoryIndex - valor do indice
 * @param {*} initDate - data inicial do contrato
 * @param {*} expirationDate - data de expiração do contrato
 * @param {*} uuidProvider - Fornecedor
 * @param cityServiceProvision - uuid da cidade da prestação de serviço
 * @param observation - Observação
 * @param serviceOrder - Ordem de serviço
 * @returns
 */
export const editContractStep1 = async (
  uuidContract,
  title,
  service,
  uuidSector,
  uuidSegment,
  uuidIndex,
  uuidHistoryIndex,
  initDate,
  expirationDate,
  uuidProvider,
  isConfidential,
  uuidCities,
  observation,
  serviceOrder
) => {
  try {
    if (isConfidential !== true && isConfidential !== false) {
      isConfidential = false;
    }

    const payload = {
      title,
      service,
      is_confidential: isConfidential,
      uuid_sector: uuidSector,
      uuid_segment: uuidSegment,
      uuid_index: uuidIndex,
      uuid_history_index: uuidHistoryIndex,
      init_date: initDate,
      expiration_date: expirationDate,
      uuid_provider: uuidProvider,
      uuid_cities: uuidCities,
      observation,
      service_order_number: serviceOrder
    };
    const { data } = await api.put(`/contract/edit/${uuidContract}/step-1`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Edita as informações financeiras do contrato
 * @param {*} uuidContract - identificador do contrato
 * @param {*} debit - débito
 * @param {*} credit - crédito
 * @param {*} readjustmentMonth - Mês de reajuste do contrato
 * @param {*} totalInstallments - total de parcelas
 * @param {*} installmentsPaid - parcelas pagas
 * @param {*} uuidCoin - moeda usada
 * @param {*} uuidCoinHistory - Cota que foi estabelecida
 * @param {*} paymentPeriodicity - período de pagamento
 * @param {*} readjustmentPeriodicity - período de reajuste
 * @param {*} initialValue - valor inicial
 * @param {*} currentValue - valor atual
 * @param {*} lateFee - Multa por atraso
 * @param {*} daysRescissionFine - Dias pra multa por rescisão de contrato
 * @param {*} fineForSalary - Multa por vencimento
 * @returns
 */
export const editContractStep2 = async (
  uuidContract,
  debit,
  credit,
  readjustmentMonth,
  totalInstallments,
  installmentsPaid,
  uuidCoin,
  uuidCoinHistory,
  paymentPeriodicity,
  readjustmentPeriodicity,
  initialValue,
  lateFee,
  daysRescissionFine,
  fineForSalary,
  lateInterest
) => {
  try {
    const payload = {
      uuid_contract: uuidContract,
      debit,
      credit,
      readjustment_month: readjustmentMonth,
      total_installments: totalInstallments,
      installments_paid: installmentsPaid,
      uuid_coin: uuidCoin,
      uuid_coin_history: uuidCoinHistory,
      payment_periodicity: paymentPeriodicity,
      readjustment_periodicity: readjustmentPeriodicity,
      initial_value: initialValue,
      traffic_ticket: {
        late_fee: lateFee,
        days_rescission_fine: daysRescissionFine,
        fine_for_salary: fineForSalary,
        late_interest: lateInterest
      }
    };
    const { data } = await api.put(`/contract/edit/step-2`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Edita o arquivo do contrato
 * @param {*} uuidContract - identificador do contrato
 * @param {*} file - arquivo do contrato em base64
 * @returns
 */
export const editContractStep3 = async (uuidContract, file) => {
  try {
    const payload = new FormData();
    payload.append('uuid_contract', uuidContract);
    payload.append('file', file);

    await api.put(`/contract/edit/step-3`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista os contratos com valores variáveis e o valores adicionados
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listContractsWithPendingValuesPaginate = async (
  initialPage,
  offset,
  order = 'ASC',
  filter = {}
) => {
  try {
    const firthPage = 1;
    const page =
      !initialPage || isNaN(initialPage) || initialPage < 0 ? firthPage : initialPage + 1;

    const { data } = await api.get(`/contract/pending-value`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Lista os do valores
 * @param {*} uuid - Identificador do contrato
 * @param {*} initialPage - Página de inicio
 * @param {*} offset - Itens por página
 * @param {*} order - ordem [asc, desc]
 * @param {*} filter - filtros
 * @returns
 */
export const listContractsValuesPaginate = async (
  uuid,
  initialPage,
  offset,
  order = 'ASC',
  filter = {}
) => {
  try {
    const firthPage = 1;
    const page =
      !initialPage || isNaN(initialPage) || initialPage < 0 ? firthPage : initialPage + 1;

    const { data } = await api.get(`/contract/list/values/${uuid}`, {
      params: {
        initial_page: page,
        offset,
        order,
        ...filter
      }
    });

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Adiciona um valor ao contrato
 * @param {*} uuid - Identificador do contrato
 * @param {*} month - Mês a ser adicionado
 * @param {*} year - Ano a ser adicionado
 * @param {*} amount - valor
 * @returns
 */
export const addValueContract = async (uuid, month, year, amount) => {
  try {
    const payload = { month, year, amount };
    const { data } = await api.post(`/contract/add-value/${uuid}`, payload);

    return data;
  } catch (error) {
    throw error?.response?.data;
  }
};

/**
 * Ativar ou inativar contrato
 * @param {*} uuidContract - identificador do contrato
 */
export const activeOrInactiveContract = async (uuidContract) => {
  try {
    await api.patch(`/contract/active-inactive/contract/${uuidContract}`);
  } catch (error) {
    throw error?.response?.data;
  }
};
