import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import {
  HiddenForPermission,
  PaginationDesktop,
  SkeletonDesktop
} from '../../../../../../components';
import { goToEditIndustry } from '../../../../../../router/coordinator';

export function DesktopTable({
  sectors,
  count,
  loading,
  page,
  offset,
  handlePage,
  handleChangeRowsPerPage,
  navigate
}) {
  const sectorsList = sectors.map((sector) => {
    return (
      <TableRow key={sector.uuid} className="not-effect">
        <TableCell>{sector.title?.toTitleCase()}</TableCell>
        <TableCell>{sector.description?.toTitleCase() || '-'}</TableCell>
        <HiddenForPermission keys="EDIT_INDUSTRY">
          <TableCell style={{ textAlign: 'right' }}>
            <IconButton onClick={() => goToEditIndustry(navigate, sector.uuid)}>
              <CreateIcon />
            </IconButton>
          </TableCell>
        </HiddenForPermission>
      </TableRow>
    );
  });

  function RenderTable() {
    if (loading) {
      return <SkeletonDesktop numbersOfColumns={3} />;
    }

    return sectorsList;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Descrição</TableCell>
              <HiddenForPermission keys="EDIT_INDUSTRY">
                <TableCell style={{ textAlign: 'right' }}>Editar</TableCell>
              </HiddenForPermission>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTable />
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
