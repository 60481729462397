import styled from 'styled-components';
import { ButtonWithIcon } from '../../../../../../../components';
import { colors } from '../../../../../../../theme/styles';

export const Price = styled.p`
  padding-top: 1rem;
  font-weight: 600;
  font-size: 1.1rem;
`;

export const ButtonAdd = styled(ButtonWithIcon)`
  && {
    padding: 0.8rem;
    border-radius: 0;
    background-color: ${colors?.theme?.primary}12;
  }
`;

export const Scroll = styled.div`
  max-height: 60vh;
  overflow-y: auto;
`;
