import React, { useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Content } from './styled';
import { BasePage } from '../../../../../components/BaseMain';
import {
  goToBack,
  goToListAdicionalsContract,
  goToListAllContracts
} from '../../../../../router/coordinator';
import { useForm } from '../../../../../hooks/useForm';
import {
  AlertMessage,
  BorderWithText,
  Loading,
  Modal,
  TextFieldNextFocus,
  TitlePages,
  UploadButton
} from '../../../../../components';
import {
  clearAlertMessage,
  handleCoin,
  onChangeDate,
  onChangeMoney,
  toCent
} from '../../../../../utils';
import { GENERIC_ERROR } from '../../../../../utils/constantes';
import {
  createAdditionalContract,
  findContract,
  findFinancialContract,
  listContractTypes
} from '../../../../../services/contractService';
import { CoinArea } from '../../ListAllContractsPage/AddContractPage/steppers/step2/areas/coinArea';
import { InformativeText } from '../../../../../components/InformativeText';
import { listIndexes } from '../../../../../services';
import AutocompleteSearch from '../../../../../components/AutocompleteSearch';
import { useUpdateState } from '../../../../../hooks/useUpdateState';

export function AddAditionalContractPage() {
  const navigate = useNavigate();
  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const breadcrumbs = [
    { title: 'Contratos', functionCoordinator: () => goToListAllContracts(navigate) },
    { title: 'Adicional', functionCoordinator: () => goToListAdicionalsContract(navigate, uuid) },
    { title: 'Cadastro' }
  ];

  const [
    {
      currencySymbol,
      loading,
      isCustomCoin,
      contract,
      contractTypes,
      coin,
      document,
      documentName,
      openModal
    },
    setStates
  ] = useUpdateState({
    indeterminatedExpiration: false,
    currencySymbol: null,
    loading: false,
    isCustomCoin: false,
    contract: {},
    contractTypes: [],
    coin: '',
    localValues: { initialDate: '', expirationDate: '', paymentPeriodicity: '' },
    openModal: false
  });
  const [alertMessage, setAlertMessage] = useUpdateState({
    title: '',
    message: '',
    severity: 'error'
  });
  const [form, onChange, clearForm, setForm] = useForm({
    uuid_contract_type: '',
    new_amount: '',
    init_date: null,
    expiration_date: null,
    observation: '',

    uuid_index: '',
    uuid_history_index: '',
    index_date: '',
    index_value: '',

    uuid_coin: '',
    uuid_coin_history: '',
    coin_date: '',
    coin_value: '',
    coin_type: ''
  });

  const init = useCallback(async () => {
    try {
      setStates({ loading: true });

      const [contract, types, financialContract] = await Promise.all([
        findContract(uuid),
        listContractTypes(),
        findFinancialContract(uuid)
      ]);

      setStates({
        contract,
        contractTypes: types,
        currencySymbol: financialContract?.coin?.title,
        coin: financialContract?.coin?.title
      });
      setForm((prev) => ({ ...prev, uuid_coin: financialContract?.coin?.uuid }));
    } catch (err) {
      setAlertMessage({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace && `Código: ${err.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const handleOpen = () => setStates({ openModal: true });
  const handleClose = () => setStates({ openModal: false });

  const createContract = async () => {
    try {
      setStates({ loading: true });
      handleClose();

      const {
        uuid_contract_type: uuidContractType,
        uuid_index: uuidIndex,
        uuid_history_index: uuidHistoryIndex,
        init_date: initDate,
        expiration_date: expirationDate,
        uuid_coin: uuidCoin,
        uuid_coin_history: uuidCoinHistory,
        observation
      } = form;

      const newAmount = form.new_amount && toCent(form.new_amount);

      await createAdditionalContract(
        uuid,
        uuidContractType,
        newAmount,
        uuidIndex,
        uuidHistoryIndex,
        uuidCoin,
        uuidCoinHistory,
        initDate,
        expirationDate,
        document,
        observation
      );

      clearForm();

      setAlertMessage({
        title: 'Adicional cadastrado com sucesso.',
        severity: 'success'
      });
    } catch (err) {
      setAlertMessage({
        title: err?.message || GENERIC_ERROR,
        message: err?.trace && `Código: ${err.trace}`
      });
    } finally {
      setStates({ loading: false });
    }
  };

  const disabledButton = () => {
    if (!form.uuid_contract_type) {
      return true;
    }
    if (!document) {
      return true;
    }

    return false;
  };

  const isDistractContract = () => {
    if (!form.uuid_contract_type) {
      return false;
    }

    const type = contractTypes.find((c) => c.uuid === form.uuid_contract_type);
    return type.title === 'DISTRATO';
  };

  const getIndexes = useCallback(async () => {
    try {
      const data = await listIndexes();
      return data;
    } catch (error) {
      setAlertMessage({
        title: error?.message || GENERIC_ERROR,
        message: error?.trace && `Código: ${error?.trace}`
      });
      return [];
    }
  }, []);

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <Loading loading={loading} />
      <TitlePages
        title={`Cadastrar novo adicional ao contrato ${contract?.id}`}
        description="Apenas o <b>tipo</b> e o <b>documento</b> são obrigatórios, já que os adicionais podem alterar apenas uma informação ou nenhuma informação"
      />

      <Content container spacing={2}>
        <AlertMessage
          message={alertMessage.message}
          severity={alertMessage.severity}
          title={alertMessage.title}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
        <Grid item xs={12} lg={3}>
          <TextField
            select
            required
            name="uuid_contract_type"
            value={form.uuid_contract_type}
            onChange={onChange}
            label="Tipo"
          >
            {contractTypes?.map((c) => (
              <MenuItem key={c.uuid} value={c.uuid}>
                {c.title?.toTitleCase()}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={3}>
          <AutocompleteSearch
            label="Índice"
            name="uuid_index"
            value={form.uuid_index}
            onChange={onChange}
            optionLabel={['name']}
            getListDataCallback={getIndexes}
            formatLabel={(label) => label?.toUpperCase?.()}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <DatePicker
            label="Data de início"
            name="init_date"
            value={form.init_date}
            onChange={(e) => onChangeDate(e, 'init_date', onChange)}
            disabled={isDistractContract()}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <DatePicker
            label={isDistractContract() ? 'Data do distrato' : 'Data de expiração'}
            name="expiration_date"
            value={form.expiration_date}
            onChange={(e) => onChangeDate(e, 'expiration_date', onChange)}
          />
        </Grid>
        <Grid item xs={12}>
          <CoinArea
            form={form}
            setForm={setForm}
            onChange={onChange}
            coin={coin}
            updateStateGlobal={setStates}
            setError={setAlertMessage}
            isCoinCustom={isCustomCoin}
            disabledCoin={false}
            disabledAll={isDistractContract()}
            isRequired={false}
          />
        </Grid>
        <Grid item xs={12}>
          <BorderWithText title="Valor">
            <Grid item xs={12}>
              <TextFieldNextFocus
                label="Novo valor"
                name="new_amount"
                disabled={isDistractContract()}
                onChange={(e) => onChangeMoney(e, onChange)}
                value={form.new_amount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{handleCoin(currencySymbol)}</InputAdornment>
                  )
                }}
              />
            </Grid>
          </BorderWithText>
        </Grid>

        <Grid item xs={12} id="upload-button">
          <UploadButton
            text="Contrato em PDF *"
            noImageMassege="Nunhum documento selecionado."
            outImageName={documentName}
            setImage={(document) => setStates({ document })}
            setOutImageName={(documentName) => setStates({ documentName })}
            noConvertToBase64
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldNextFocus
            label="Observação"
            multiline
            rows={4}
            onChange={onChange}
            name="observation"
            value={form.observation}
          />
        </Grid>

        <InformativeText />
        <Grid item className="buttonGroup twoButtons nearTheTop">
          <Button variant="outlined" onClick={() => goToBack(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen} disabled={disabledButton()}>
            Cadastrar
          </Button>
        </Grid>
      </Content>
      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={createContract}
        title="Criar contrato adicional"
        text={`Tem certeza que deseja criar um novo contrato adicional para o ${contract?.id}?`}
        warning="As informações não poderão ser editadas ou excluidas após sua criação."
      />
    </BasePage>
  );
}
