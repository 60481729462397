import {
  HiddenForPermission,
  PaginationDesktop,
  SkeletonMobile,
  TargetStatus
} from '../../../../../components';
import {
  MobileTableConteiner,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle
} from '../../../../../components/MobileTable';
import {
  goToEditAdditionalContract,
  goToVisualizeAdditionalContract
} from '../../../../../router/coordinator';
import { convertCentsToWhole } from '../../../../../utils/convert';

export function MobileTable({
  contracts,
  navigate,
  count,
  loading,
  page,
  offset,
  handlePage,
  handleChangeRowsPerPage,
  handleModal
}) {
  const contractsList = contracts?.map((contract) => {
    return (
      <TableMobileCell key={contract?.id}>
        <TableMobileItem>
          <TableMobileTitle>Ordem </TableMobileTitle>
          <TableMobileContent>{contract?.order && `${contract?.order}°`}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Tipo </TableMobileTitle>
          <TableMobileContent>
            <TargetStatus status={contract.contractType?.title} noCircle variant="solid" />
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Índice</TableMobileTitle>
          <TableMobileContent>{contract.index?.name?.toUpperCase?.() || '-'}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Moeda</TableMobileTitle>
          <TableMobileContent>{contract.coin?.title?.toTitleCase?.() || '-'}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Cotação em real</TableMobileTitle>
          <TableMobileContent>
            {convertCentsToWhole(contract.coinHistory?.exchangeInReal) || '-'}
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Valor</TableMobileTitle>
          <TableMobileContent>
            {contract?.newAmount ? convertCentsToWhole(contract?.newAmount, 'real') : '-'}
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Data inicial </TableMobileTitle>
          <TableMobileContent>
            {contract?.initDate ? contract?.initDate.toMask('date') : '-'}
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Data de expiração ou distrato </TableMobileTitle>
          <TableMobileContent>
            {contract?.expirationDate ? contract?.expirationDate.toMask('date') : '-'}
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Observação</TableMobileTitle>
          <TableMobileContent>{contract.observation || '-'}</TableMobileContent>
        </TableMobileItem>
        <HiddenForPermission keys="FIND_FILE_ADDITIONAL_CONTRACT">
          <TableMobileButton
            onClick={() => goToVisualizeAdditionalContract(navigate, contract?.uuid)}
          >
            Visualizar
          </TableMobileButton>
        </HiddenForPermission>
        <HiddenForPermission keys="EDIT_ADDITIONAL_CONTRACT">
          <TableMobileButton onClick={() => goToEditAdditionalContract(navigate, contract?.uuid)}>
            Editar
          </TableMobileButton>
        </HiddenForPermission>
        <HiddenForPermission keys="DELETE_ADITTIONAL_CONTRACT">
          <TableMobileButton
            color="error"
            onClick={() => {
              handleModal(contract.uuid);
            }}
          >
            Deletar contrato
          </TableMobileButton>
        </HiddenForPermission>
      </TableMobileCell>
    );
  });

  return (
    <MobileTableConteiner>
      {!loading ? contractsList : <SkeletonMobile numbersOfColumns={5} />}
      <PaginationDesktop
        count={count}
        page={page}
        rowsPerPage={offset}
        handlePage={handlePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </MobileTableConteiner>
  );
}
