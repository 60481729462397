import { Button } from './styled';

export function ButtonWithIcon({ Icon, children, ...props }) {
  return (
    <Button {...props}>
      <Icon />
      {children && <span>{children}</span>}
    </Button>
  );
}
