import useMediaQuery from '@mui/material/useMediaQuery';
import { getItemLocalStorage } from '../services/localStorageService';
import { Status } from './enums';
import { colors } from '../theme/styles';

/**
 * Verifica a largura da tela a fim de criar uma interface responsiva
 * Valor configurado: 768px (acima disso é considerado desktop)
 * @returns
 */
export const isDesktop = () => {
  return useMediaQuery('(min-width:769px)');
};

/**
 * Retira da string qualquer caracter não numérico
 * @param value
 * @returns
 */
export const onlyNumbers = (value) => {
  return value ? value.replace(/\D/g, '') : '';
};

/**
 * Retorna o símbolo da moeda selecionada
 */
export const handleCoin = (coin) => {
  if (!coin) {
    return null;
  }

  switch (coin.toLowerCase()) {
    case 'real':
      return 'R$';

    case 'euro':
      return '€';

    case 'dólar':
      return 'US$';

    case 'dolar':
      return 'US$';

    default:
      return 'R$';
  }
};

/**
 * Faz o scroll até o inicio da tela com uma animação de 500ms
 * @returns
 */
export const scrollToTopPage = () => {
  const scrollToTopElement = document.documentElement || document.body;
  const scrollStep = -scrollToTopElement.scrollTop / (200 / 15); // Ajuste a duração da animação aqui (500 ms)

  const scrollAnimation = setInterval(() => {
    if (scrollToTopElement.scrollTop !== 0) {
      scrollToTopElement.scrollBy(0, scrollStep);
    } else {
      clearInterval(scrollAnimation);
    }
  }, 15);
};

/**
 * Máscara o valor a partir de uma máscara [ex de uma de cpf: ###.###.###-##]
 * @param {*} value
 * @param {*} mask
 * @returns
 */
const onChangeMask = (value, mask) => {
  // Reverte o valor e a máscara para começar pelos decimais
  const reverseValue = value.replace(/[^\d]+/gi, '').reverse();
  const maskReverse = mask.reverse();

  let result = '';

  for (let x = 0, y = 0; x < maskReverse.length && y < reverseValue.length; ) {
    // Se o caracter da posição não for '#' ele adiciona
    // ao resulta pois é o ponto ou a vígula
    if (maskReverse.charAt(x) !== '#') {
      result += maskReverse.charAt(x);
      x++;
    } else {
      // Se o caracter for '#' ele põe o número e continua
      result += reverseValue.charAt(y);
      y++;
      x++;
    }
  }

  return result.reverse();
};

/**
 * Máscara de dinhero para o input
 * @param {*} event
 * @param {*} onChange
 */
export const onChangeMoney = (event, onChange) => {
  const { value, name } = event.target;

  const result = onChangeMask(value, '###.###.###.###,##');

  const target = {
    name,
    value: result
  };
  onChange({ target });
};

/**
 * Máscara de porcentagem para o input
 * @param {*} event
 * @param {*} onChange
 */
export const onChangePercent = (event, onChange, mask = '##,##') => {
  const { value, name } = event.target;

  const result = onChangeMask(value, mask);

  const target = {
    name,
    value: result
  };
  onChange({ target });
};

/**
 * onChange para lidar com datas
 * @param {*} event
 * @param {*} name
 * @param {*} onChange
 */
export const onChangeDate = (event, name, onChange) => {
  const target = {
    name,
    value: event
  };

  onChange({ target });
};

/**
 * Retorna um array sem itens repetidos
 * @param {*} arr
 * @returns
 */
export const noRepeatArray = (arr) => arr.filter((item, i) => arr.indexOf(item) === i);

/**
 * Transforma nome e sobrenome em nome completo
 * @param {*} name - primeiro nome
 * @param {*} lastName - sobrenome
 */
export const fullname = (name, lastName) =>
  name && lastName && `${name.toTitleCase()} ${lastName.toTitleCase()}`;

export const formatDate = (date, template = 'DD/MM/YYYY') => {
  return date.format(template);
};

/**
 * Verifica se o usuário logado tem autorização pra fazer algo
 * @param {*} key - chave da permissão
 * @returns
 */
export const isAuth = (key) => {
  const permissions = getItemLocalStorage('permissions');
  const havePermission = permissions?.find((p) => p === key);

  return !!havePermission;
};

/**
 * Retira o simbolo de porcentagem se houver e retorna um float
 * @param {*} percent
 * @returns
 */
export const clearPercent = (percent) => {
  if (!percent) {
    return percent;
  }

  const fromBrToEUA = percent.replace(',', '.');

  return parseFloat(fromBrToEUA);
};

/**
 * Pega uma string ou valor e transforma em centavos
 * @param {*} amount
 * @returns
 */
export const toCent = (amount) => {
  if (!amount || typeof amount === 'number') {
    return amount;
  }

  const fromBrToEUA = amount?.replace('.', '').replace(',', '.');
  const amountFloat = parseFloat(fromBrToEUA);

  return amountFloat * 100;
};

/**
 * converte base64 para blob
 * @param {*} data - base64
 * @returns
 */
export const base64toBlob = (data) => {
  const bytes = atob(data);
  let length = bytes.length;
  const out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: 'application/pdf' });
};

/**
 * Verifica se o usuário tem uma permissão
 * @param {*} key - chave da permissão
 * @returns
 */
export const hasPermission = (keys) => {
  const user = getItemLocalStorage('user');
  if (!user || !user?.office) {
    return false;
  }

  // Se for master ou dev ele n verifica permissão
  if (user.office === 'MASTER' || user.office === 'DEVELOPER') {
    return true;
  }

  const permissions = getItemLocalStorage('permissions');
  if (Array.isArray(keys)) {
    return permissions.some((key) => keys.includes(key));
  }

  return permissions.includes(keys);
};

/**
 * Pega o mimeType de um base64
 * @param {*} base64String
 * @returns
 */
export const getMimeTypeFromBase64 = (base64String) => {
  const header = atob(base64String.slice(0, 100));

  // Lista de padrões de assinatura e seus respectivos tipos MIME
  const signatureMap = {
    '%PDF': 'application/pdf',
    PK: 'application/zip',
    'Rar!': 'application/x-rar-compressed'
  };

  // Verifica os padrões de assinatura
  for (const signature in signatureMap) {
    if (header.startsWith(signature)) {
      return signatureMap[signature];
    }
  }

  // Se nenhum tipo MIME correspondente for encontrado, retorna null
  return null;
};

/**
 * Possibilita lidar com o erro sem estourar uma exception no catch
 * @param promise - promessa que vai ter retorno controlado
 * @returns [erro, resposta]
 */
export const handle = async (promise) => {
  return promise.then((response) => [undefined, response]).catch((error) => [error, undefined]);
};

/**
 * Limpa a mensagem de alerta
 * @param {*} setAlertMessage
 */
export const clearAlertMessage = (setAlertMessage) => {
  setAlertMessage({ title: '', message: '', severity: '' });
};

export const translateStatusColor = (status) => {
  let backgroundColor = '';
  let colorsStatus = '';

  const greenStatus = [Status.ACTIVE, Status.ADDITIVE];
  const redStatus = [
    Status.BLOCKED,
    Status.DELETED,
    Status.INACTIVE,
    Status.REPROVED,
    Status.DISTRACTION
  ];
  const yellowStatus = [Status.PENDING];
  const greyStatus = [Status.ALL];

  if (greenStatus.includes(status.toLowerCase())) {
    backgroundColor = colors?.status?.backgroundColor?.green;
    colorsStatus = colors?.status?.text?.green;
  } else if (redStatus.includes(status.toLowerCase())) {
    backgroundColor = colors?.status?.backgroundColor?.red;
    colorsStatus = colors?.status?.text?.red;
  } else if (yellowStatus.includes(status.toLowerCase())) {
    backgroundColor = colors?.status?.backgroundColor?.yellow;
    colorsStatus = colors?.status?.text?.yellow;
  } else if (greyStatus.includes(status.toLowerCase())) {
    backgroundColor = colors?.status?.backgroundColor?.grey;
    colorsStatus = colors?.status?.text?.grey;
  }

  return { backgroundColor, colorsStatus };
};
