import CardLayout from './Card';
import Layout from './Container';
import Item from './Item';
import Title from './Title';

const ResizedLayout = {
  Container: Layout,
  Card: CardLayout,
  Title,
  Item
};

export default ResizedLayout;
