import React from 'react';
import { Divider } from '@mui/material';
import { Container, Content, Link } from './styled';

export default function Title({ children, onLink, linkText }) {
  return (
    <Container item lg={12}>
      <Content>
        <h3>{children}</h3>
        {onLink && <Link onClick={onLink}>{linkText || 'Ver mais'}</Link>}
      </Content>
      <Divider />
    </Container>
  );
}
