// Funções para navegação entre página

import { scrollToTopPage } from '../utils';

export const goToBack = (navigate) => {
  navigate(-1);
};

// Credenciais
export const goToSignup = (navigate) => {
  scrollToTopPage();
  navigate('/credenciais/cadastro');
};

export const goToLogin = (navigate) => {
  scrollToTopPage();
  navigate('/credenciais/entrar');
};

export const goToCreatePassWord = (navigate) => {
  scrollToTopPage();
  navigate('/credenciais/cadastrar-senha');
};

export const goToAccountCreated = (navigate) => {
  scrollToTopPage();
  navigate('/credenciais/conta-criada');
};

export const goToForgotPassword = (navigate) => {
  scrollToTopPage();
  navigate('/credenciais/esqueci-senha');
};

export const goToRedefinePasswordForEmail = (navigate) => {
  scrollToTopPage();
  navigate('/credenciais/redefinir-senha');
};

// Antes de entrar
export const goToTerms = (navigate) => {
  scrollToTopPage();
  navigate('/termos');
};

export const goToChooseCompany = (navigate) => {
  scrollToTopPage();
  navigate('/escolher-empresa');
};

// Home
export const goToHome = (navigate) => {
  scrollToTopPage();
  navigate('/');
};

// Menu
export const goToEditAccount = (navigate) => {
  scrollToTopPage();
  navigate('/menu/editar-conta');
};

export const goToRedefinePassword = (navigate) => {
  scrollToTopPage();
  navigate('/menu/redefinir-senha');
};

export const goToTermsAndGuidelines = (navigate) => {
  scrollToTopPage();
  navigate('/menu/termos-e-diretrizes');
};

export const goToDetailsTermsAndGuidelines = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/menu/termos-e-diretrizes/detalhes/${uuid}`);
};

// Contratos
export const goToListAllContracts = (navigate) => {
  scrollToTopPage();
  navigate('/contratos');
};

export const goToExpiringContracts = (navigate) => {
  scrollToTopPage();
  navigate('/contratos/expirando');
};

export const goToContractsFines = (navigate) => {
  scrollToTopPage();
  navigate('/contratos/multa-rescisoria');
};

export const goToAddedContracts = (navigate) => {
  scrollToTopPage();
  navigate('/contratos/adicionados');
};

export const goToAddContract = (navigate) => {
  scrollToTopPage();
  navigate('/contratos/cadastrar');
};

export const goToDatailsContract = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/contratos/detalhes/${uuid}`);
};

export const goToDatailsFinancialContract = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/contratos/detalhes/${uuid}/financeiro`);
};

export const goToVisualizeContract = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/contratos/detalhes/${uuid}/visualizar`);
};

export const goToVisualizeAdditionalContract = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/contratos/detalhes/${uuid}/contratos-adicionais/visualizar`);
};

export const goToEditAdditionalContract = (navigate, uuidAdditionalContract, uuidContract) => {
  scrollToTopPage();
  navigate(`/contratos/${uuidContract}/contratos-adicionais/${uuidAdditionalContract}/editar`);
};

export const goToListAdicionalsContract = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/contratos/detalhes/${uuid}/contratos-adicionais`);
};

export const goToAddAdicionalsContract = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/contratos/detalhes/${uuid}/contratos-adicionais/cadastrar`);
};

export const goToEditContract = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/contratos/${uuid}/editar`);
};

export const goToContractValuesVariables = (navigate) => {
  scrollToTopPage();
  navigate(`/contratos/valores-variáveis`);
};

export const goToContractAllValues = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/contratos/valores-variáveis/${uuid}/valores`);
};

export const goToContractAddValue = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/contratos/valores-variáveis/${uuid}/adicionar`);
};

// Financeiro
export const goToFinancial = (navigate) => {
  scrollToTopPage();
  navigate('/financeiro');
};

// Admin
export const goToAdmin = (navigate) => {
  scrollToTopPage();
  navigate('/administrativo');
};

// --- User
export const goToUsersList = (navigate) => {
  scrollToTopPage();
  navigate('/administrativo/usuarios');
};

export const goToDatailsUser = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/usuarios/${uuid}/detalhes`);
};

export const goToRegisterNewUser = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/usuarios/registrar`);
};

export const goToChoiceCompanyPermission = (navigate, uuidUser) => {
  scrollToTopPage();
  navigate(`/administrativo/usuarios/registrar/${uuidUser}/permissao-empresas`);
};

export const goToChoicePermission = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/usuarios/registrar/permissao/${uuid}`);
};

export const goToConcludedRegisterUser = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/usuarios/registrar/concluido`);
};

export const goToUserContractsAdded = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/usuarios/${uuid}/contratos-adicionados`);
};

export const goToUserCompanyPermission = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/usuarios/${uuid}/permissoes-empresa`);
};

export const goToUserPermission = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/usuarios/${uuid}/permissoes`);
};

export const goToEditUserAdmin = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/usuarios/${uuid}/editar`);
};

// ---- Empresa
export const goToCompanyInformation = (navigate, uuid, blank = false) => {
  scrollToTopPage();
  const path = `/administrativo/empresa/${uuid}/detalhes`;

  if (blank) {
    window.open(path);
  } else {
    navigate(path);
  }
};

export const goToEditCompanyInformation = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/empresa/${uuid}/editar`);
};

export const goToListCompanies = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/empresa/todas`);
};

export const goToCreateCompany = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/empresa/criar`);
};

// ---- Setores
export const goToSectorsList = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/setores`);
};

export const goToEditSectors = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/setores/${uuid}/editar`);
};

export const goToCreateSectors = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/setores/cadastrar`);
};

// ---- Indices
export const goToIndexList = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/indices`);
};

export const goToIndexHistory = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/indices/${uuid}/historico`);
};

export const goToCreateIndex = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/indices/cadastrar`);
};

export const goToCreateHistoryIndex = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/indices/${uuid}/cadastrar/historico`);
};

export const goToEditHistoryIndex = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/indices/historico/${uuid}/editar`);
};

// ---- Moedas
export const goToCoinList = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/moeda`);
};

export const goToCoinHistory = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/moeda/${uuid}/historico`);
};

export const goToCreateCoin = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/moeda/cadastrar`);
};

export const goToEditCoin = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/moeda/${uuid}/editar`);
};

export const goToCreateHistoryCoin = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/moeda/${uuid}/cadastrar/historico`);
};

// ---- Fornecedores
export const goToProvidersList = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/fornecedores`);
};

export const goToCreateProvider = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/fornecedores/criar`);
};

export const goToProviderDetails = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/fornecedores/${uuid}/detalhes`);
};

export const goToEditProvider = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/fornecedores/${uuid}/editar`);
};

// ---- Setores empresa
export const goToIndustryList = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/setores-empresa`);
};

export const goToIndustryCreate = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/setores-empresa/cadastrar`);
};

export const goToEditIndustry = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/setores-empresa/${uuid}/editar`);
};

// ---- Segmentos
export const goToSegmentList = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/segmentos`);
};

export const goToSegmentCreate = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/segmentos/cadastrar`);
};

export const goToEditSegment = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/segmentos/${uuid}/editar`);
};

// ---- Templates
export const goToTemplatesList = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/template`);
};

export const goToEditTemplate = (navigate, uuid) => {
  scrollToTopPage();
  navigate(`/administrativo/template/${uuid}/editar`);
};

// ---- Arquivo ajuda
export const goToCreateHelpFile = (navigate) => {
  scrollToTopPage();
  navigate(`/administrativo/arquivo-ajuda`);
};

export const goToHelpFileList = (navigate) => {
  scrollToTopPage();
  navigate(`/ajuda`);
};
