import { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { CoinArea, ValueArea, TrafficTicketArea } from './areas';
import { isDesktop, onChangeDate, onlyNumbers } from '../../../../../../utils';
import { AlertMessage, Loading, TextFieldNextFocus } from '../../../../../../components';
import { monthsOfTheYear } from '../../../../../../utils/monthsOfTheYear';
import { useForm } from '../../../../../../hooks/useForm';
import { InformativeText } from '../../../../../../components/InformativeText';

export function FinancialInformations({ onNextStep, onBackStep, setForm, allForm }) {
  const initialForm = {
    credit: '',
    debit: '',
    readjustment_month: '',
    init_date: null,
    expiration_date: null,
    uuid_coin: '',
    uuid_coin_history: '',
    initial_value: '',
    total_installments: '',
    installments_paid: '',
    payment_periodicity: '',
    readjustment_periodicity: '',

    late_fee: '',
    fine_for_salary: '',
    days_rescission_fine: '',
    late_interest: '',

    exchange_date: null,
    exchang_type: '',
    exchang_value: ''
  };
  const [financialInformations, onChange, _, setFinancialInformations] = useForm(initialForm);
  const [errorsValidation, setErrorsValidation] = useState({});
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [isCoinCustom, setIsCoinCustom] = useState(false);
  const [indeterminatedExpiration, setIndeterminatedExpiration] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    title: '',
    message: '',
    severity: ''
  });
  const [localValues, setLocalValues] = useState({
    initialDate: '',
    expirationDate: '',
    payment_periodicity: ''
  });

  const init = useCallback(async () => {
    getExistForm();
    isCustomCoin();
  }, []);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    getExistForm();
    getCurrencySymbol();
    isCustomCoin();
  }, [allForm]);

  useEffect(() => {
    if (indeterminatedExpiration) {
      handleIndeterminatedDateChange();
      return;
    }

    handleExpiryDateChange();
  }, [financialInformations, indeterminatedExpiration]);

  const calculateInstallments = (months, periodicity) => {
    if (periodicity <= 0) {
      return 0;
    }
    return parseInt(months / periodicity);
  };

  const handleIndeterminatedDateChange = () => {
    const {
      init_date: initDate,
      expiration_date: expirationDate,
      payment_periodicity: paymentPeriodicity
    } = financialInformations;
    const {
      initialDate: initialDateLocal,
      expirationDate: expirationDateLocal,
      paymentPeriodicity: paymentPeriodicityLocal
    } = localValues;

    const haveValue = initDate && paymentPeriodicity;
    const isDateChanged =
      haveValue &&
      (initDate !== initialDateLocal ||
        expirationDate !== expirationDateLocal ||
        paymentPeriodicity !== paymentPeriodicityLocal);

    if (expirationDate) {
      setFinancialInformations((prev) => ({
        ...prev,
        expiration_date: null
      }));
    }

    if (isDateChanged) {
      handleIndeterminatedInstallments();
    }
  };

  const handleIndeterminatedInstallments = () => {
    setLocalValues((prev) => ({
      ...prev,
      initialDate: financialInformations.init_date,
      expirationDate: null,
      paymentPeriodicity: financialInformations.payment_periodicity
    }));

    const initDate = dayjs(financialInformations.init_date);

    const isValidDate = initDate.isValid();
    if (!isValidDate) {
      return;
    }

    // Calcula a diferença em meses entre as datas
    const paidMonths = dayjs().diff(initDate, 'month');

    if (isNaN(paidMonths)) {
      return;
    }

    const { payment_periodicity: periodicity } = financialInformations;

    // Calcula o número de parcelas totais e pagas com base na periodicidade
    let paidInstallments = calculateInstallments(paidMonths, periodicity);

    if (paidInstallments < 0) {
      paidInstallments = 0;
    }

    setFinancialInformations((prevForm) => ({
      ...prevForm,
      installments_paid: paidInstallments,
      total_installments: 0,
      expiration_date: null
    }));
  };

  const handleExpiryDateChange = () => {
    const {
      init_date: initDate,
      expiration_date: expirationDate,
      payment_periodicity: paymentPeriodicity
    } = financialInformations;
    const {
      initialDate: initialDateLocal,
      expirationDate: expirationDateLocal,
      paymentPeriodicity: paymentPeriodicityLocal
    } = localValues;

    const haveValue = initDate && expirationDate && paymentPeriodicity;

    const isDateChanged =
      haveValue &&
      (initDate !== initialDateLocal ||
        expirationDate !== expirationDateLocal ||
        paymentPeriodicity !== paymentPeriodicityLocal);

    if (isDateChanged) {
      handleDeterminatedInstallments();
    }
  };

  const handleDeterminatedInstallments = () => {
    setLocalValues((prev) => ({
      ...prev,
      initialDate: financialInformations.init_date,
      expirationDate: financialInformations.expiration_date,
      paymentPeriodicity: financialInformations.payment_periodicity
    }));

    const initDate = dayjs(financialInformations.init_date);
    const expirationDate = dayjs(financialInformations.expiration_date);

    const isValidDates = initDate.isValid() && expirationDate.isValid();
    if (!isValidDates) {
      return;
    }

    // Calcula a diferença em meses entre as datas
    const totalMonths = expirationDate.diff(initDate, 'month');
    const paidMonths = dayjs().diff(initDate, 'month');

    if (isNaN(totalMonths) || isNaN(paidMonths)) {
      return;
    }

    const { payment_periodicity: periodicity } = financialInformations;

    // Calcula o número de parcelas totais e pagas com base na periodicidade
    const totalInstallments = calculateInstallments(totalMonths, periodicity);
    let paidInstallments = calculateInstallments(paidMonths, periodicity);

    if (paidInstallments > totalInstallments) {
      paidInstallments = totalInstallments;
    }

    setFinancialInformations((prevForm) => ({
      ...prevForm,
      total_installments: totalInstallments,
      installments_paid: paidInstallments
    }));
  };

  const clearError = () =>
    setErrors((prev) => ({
      ...prev,
      title: '',
      severity: '',
      message: ''
    }));

  const isCustomCoin = () => {
    if (allForm.is_custom_coin !== undefined) {
      setIsCoinCustom(allForm.is_custom_coin);
    }
  };

  const getExistForm = () => {
    // Se já tiver informações no formulario principal ele é preenchido novamente
    for (const key in financialInformations) {
      setFinancialInformations((prevValue) => ({
        ...prevValue,
        [key]: allForm[key] || initialForm[key]
      }));
    }

    setIndeterminatedExpiration(allForm?.indeterminatedExpiration || !allForm?.expiration_date);
  };

  const getCurrencySymbol = () => {
    if (allForm.coin_symbol) {
      setCurrencySymbol(allForm.coin_symbol);
    }
  };

  /**
   * Gera erro se um input obrigatório não está preenchido
   * @param {*} field - campo a ser verificado
   * @returns
   */
  const validateMandatoryInput = (field) => {
    if (
      !financialInformations[field] ||
      (!financialInformations[field] && financialInformations[field] !== 0)
    ) {
      setErrorsValidation((prevError) => ({ ...prevError, [field]: true }));
      return;
    }
    setErrorsValidation((prevError) => ({ ...prevError, [field]: false }));
  };

  /**
   * Gera erro se o valor da conta de débito ou crédito
   * estiver vazio ou for menos que 9 caracteres
   * @param {*} field - campo a ser verificado [credit, debit]
   * @returns
   */
  const validateDebitCreditInput = (field) => {
    const value = onlyNumbers(financialInformations[field]);

    if (!value || value.length !== 6) {
      setErrorsValidation((prevError) => ({ ...prevError, [field]: true }));
      return;
    }

    setErrorsValidation((prevError) => ({ ...prevError, [field]: false }));
  };

  const validForm = () => {
    const { init_date: initialDate, expiration_date: expirationDate } = financialInformations;
    if (dayjs(initialDate).isAfter(expirationDate)) {
      setErrors((prev) => ({
        ...prev,
        title: 'Datas do contrato',
        message: 'A data de expiração é anterior a data de inicio.'
      }));
      return;
    }

    const { total_installments: totalInstallments, installments_paid: paidInstallments } =
      financialInformations;
    if (paidInstallments > totalInstallments) {
      setErrors((prev) => ({
        ...prev,
        title: 'Parcelas do contrato',
        message: 'O número de parcelas pagas é maior que o número de parcelas totais.'
      }));
      return;
    }
  };

  const nextStep = () => {
    validForm();
    clearError();

    setForm((prevForm) => ({ ...prevForm, ...financialInformations, indeterminatedExpiration }));
    onNextStep();
  };

  const backStep = () => {
    setForm((prevForm) => ({ ...prevForm, ...financialInformations, indeterminatedExpiration }));
    onBackStep();
  };

  const disabledButton = () => {
    if (!financialInformations.credit || errorsValidation.credit) {
      return true;
    }
    if (!financialInformations.debit || errorsValidation.debit) {
      return true;
    }
    if (!financialInformations.readjustment_month) {
      return true;
    }
    if (!financialInformations.init_date) {
      return true;
    }
    if (!indeterminatedExpiration && !financialInformations.expiration_date) {
      return true;
    }
    if (!indeterminatedExpiration && !financialInformations.total_installments) {
      return true;
    }
    if (!financialInformations.installments_paid && financialInformations.installments_paid !== 0) {
      return true;
    }
    if (!financialInformations.uuid_coin) {
      return true;
    }
    if (!financialInformations.initial_value) {
      return true;
    }
    if (!financialInformations.payment_periodicity) {
      return true;
    }
    if (!financialInformations.readjustment_periodicity) {
      return true;
    }

    return false;
  };

  return (
    <Grid container xs={12} rowGap={2} columnSpacing={isDesktop() && 2}>
      <Loading loading={loading} />
      <Grid item xs={12}>
        <AlertMessage
          title={errors.title}
          message={errors.message}
          severity={errors.severity}
          onClose={errors.onClose}
        />
      </Grid>

      <Grid item xs={12} lg={3}>
        <ReactInputMask
          mask="999999"
          maskChar="_"
          value={financialInformations.credit}
          onChange={onChange}
          onBlur={() => validateDebitCreditInput('credit')}
        >
          {() => (
            <TextFieldNextFocus
              label="Conta reduzida de débito"
              required
              name="credit"
              value={financialInformations.credit}
              error={errorsValidation.credit}
              helperText={errorsValidation.credit && 'O número da conta de crédito está inválido.'}
            />
          )}
        </ReactInputMask>
      </Grid>
      <Grid item xs={12} lg={3}>
        <ReactInputMask
          mask="999999"
          maskChar="_"
          value={financialInformations.debit}
          onChange={onChange}
          onBlur={() => validateDebitCreditInput('debit')}
        >
          {() => (
            <TextFieldNextFocus
              label="Conta reduzida de crédito"
              required
              name="debit"
              value={financialInformations.debit}
              error={errorsValidation.debit}
              helperText={errorsValidation.debit && 'O número da conta de débito está inválido.'}
            />
          )}
        </ReactInputMask>
      </Grid>
      <Grid item xs={12} lg={3}>
        <TextField
          select
          required
          label="Periodicidade de reajuste"
          name="readjustment_periodicity"
          value={financialInformations.readjustment_periodicity}
          onChange={onChange}
          onBlur={() => validateMandatoryInput('readjustment_periodicity')}
          error={errorsValidation.readjustment_periodicity}
          helperText={
            errorsValidation.readjustment_periodicity && 'O período de reajuste é obrigatório'
          }
        >
          <MenuItem value={1}>Mensal</MenuItem>
          <MenuItem value={3}>Trimestral</MenuItem>
          <MenuItem value={6}>Semestral</MenuItem>
          <MenuItem value={12}>Anual</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} lg={3}>
        <TextField
          select
          required
          label="Mês de reajuste"
          name="readjustment_month"
          value={financialInformations.readjustment_month}
          onChange={onChange}
          onBlur={() => validateMandatoryInput('readjustment_month')}
          error={errorsValidation.readjustment_month}
          helperText={errorsValidation.readjustment_month && 'O mês de reajuste é obrigatório.'}
        >
          {monthsOfTheYear.map((v) => (
            <MenuItem key={v.value} value={v.value}>
              {v.text}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} lg={4}>
        <TextField
          select
          required
          label="Periodicidade de pagamento"
          name="payment_periodicity"
          value={financialInformations.payment_periodicity}
          onChange={onChange}
          onBlur={() => validateMandatoryInput('payment_periodicity')}
          error={errorsValidation.payment_periodicity}
          helperText={
            errorsValidation.payment_periodicity
              ? 'O período de pagamento é obrigatório'
              : 'Este campo é usado pra fazer o cálculo das parcelas'
          }
        >
          <MenuItem value={1}>Mensal</MenuItem>
          <MenuItem value={3}>Trimestral</MenuItem>
          <MenuItem value={6}>Semestral</MenuItem>
          <MenuItem value={12}>Anual</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} lg={4}>
        <DatePicker
          label="Data de início *"
          required
          name="init_date"
          value={financialInformations.init_date}
          onChange={(e) => onChangeDate(e, 'init_date', onChange)}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <DatePicker
          label={`Data de expiração ${!indeterminatedExpiration ? '*' : ''}`}
          required
          name="expiration_date"
          value={financialInformations.expiration_date}
          onChange={(e) => onChangeDate(e, 'expiration_date', onChange)}
          disabled={indeterminatedExpiration}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="indeterminatedExpiration"
              checked={indeterminatedExpiration}
              onChange={({ target }) => setIndeterminatedExpiration(target?.checked)}
            />
          }
          label="Este contrato tem data de expiração indeterminada."
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextFieldNextFocus
          required={!indeterminatedExpiration}
          type="number"
          label="Quantidade de parcelas totais"
          name="total_installments"
          value={financialInformations.total_installments || 0}
          onChange={onChange}
          onBlur={() => validateMandatoryInput('total_installments')}
          error={errorsValidation.total_installments && !indeterminatedExpiration}
          helperText={
            errorsValidation.total_installments && !indeterminatedExpiration
              ? 'A quantidade total de parcelas é obrigatória.'
              : 'Valide o valor gerado nesse campo antes de confirmar.'
          }
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextFieldNextFocus
          required
          type="number"
          label="Quantidade de parcelas pagas"
          name="installments_paid"
          value={financialInformations.installments_paid}
          onChange={onChange}
          onBlur={() => validateMandatoryInput('installments_paid')}
          error={errorsValidation.installments_paid}
          helperText={
            errorsValidation.installments_paid
              ? 'A quantidade de parcelas pagas é obrigatória.'
              : 'Valide o valor gerado nesse campo antes de confirmar.'
          }
        />
      </Grid>

      <Grid item xs={12}>
        <CoinArea
          form={financialInformations}
          setForm={setFinancialInformations}
          onChange={onChange}
          coin={currencySymbol}
          setCoin={setCurrencySymbol}
          setError={setErrors}
          setLoading={setLoading}
          isCoinCustom={isCoinCustom}
          setIsCoinCustom={setIsCoinCustom}
          isRequired={true}
        />
      </Grid>

      <Grid item xs={12}>
        <ValueArea form={financialInformations} onChange={onChange} coin={currencySymbol} />
      </Grid>

      <Grid item xs={12}>
        <TrafficTicketArea form={financialInformations} onChange={onChange} />
      </Grid>

      <InformativeText />

      <Grid item className="buttonGroup twoButtons nearTheTop">
        <Button onClick={backStep} variant="outlined">
          Voltar
        </Button>
        <Button onClick={nextStep} disabled={disabledButton()}>
          Próximo
        </Button>
      </Grid>
    </Grid>
  );
}
