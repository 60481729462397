import { isDesktop } from '../../utils';

// Variáveis para padronização de metricas (padding, margin, etc)
export const metrics = {
  padding: {
    content: () => (isDesktop() ? '0 5rem' : 0),
    terms: () => (isDesktop() ? '3rem' : '1rem'),
    header: () => (isDesktop() ? '1rem' : '0 1.5rem')
  },
  weight: {
    regular: 400,
    medium: 500,
    bold: 900
  },
  margin: {
    regular: '1rem'
  },
  size: {
    small: '1rem',
    smallMedium: '1.5rem',
    medium: () => (isDesktop() ? '2rem' : '1.5rem'),
    mediumLarge: '2.5rem',
    large: '3rem'
  },

  sizeIconCredential: () => (isDesktop() ? 170 : 140),
  titleCredential: () => (isDesktop() ? '30pt' : '20pt'),
  buttonUpload: {
    width: () => (isDesktop() ? '10rem' : '100%')
  },
  widthContentChooseCompany: () => (isDesktop() ? '33%' : '100%')
};
