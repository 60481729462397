import { HiddenForPermission, SkeletonMobile, TargetStatus } from '../../../../../components';
import { PaginationMobile } from '../../../../../components/pagintations/PaginationMobile';
import {
  MobileTableConteiner,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle
} from '../../../../../components/MobileTable';
import { convertCentsToWhole, convertReadjustmentMonth } from '../../../../../utils/convert';

export function MobileTable({ contracts, loading, count, page, offset, handlePage, goToDetails }) {
  const contractsList =
    contracts &&
    contracts.map((c) => {
      return (
        <TableMobileCell key={c.id}>
          <TableMobileItem>
            <TableMobileTitle>Id </TableMobileTitle>
            <TableMobileContent>{c.id || '-'}</TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Status </TableMobileTitle>
            <TableMobileContent>
              <TargetStatus status={c.status} />
            </TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Segmento </TableMobileTitle>
            <TableMobileContent>{c.segments?.title?.toTitleCase() || '-'}</TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Fornecedor </TableMobileTitle>
            <TableMobileContent>{c.provider?.name?.toCapitalizeCase()}</TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Data de início </TableMobileTitle>
            <TableMobileContent>{c.initDate?.toMask('date')}</TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Data de expiração </TableMobileTitle>
            <TableMobileContent>{c.expirationDate?.toMask('date') || '-'}</TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Índice </TableMobileTitle>
            <TableMobileContent>
              {c.indexCurrent?.name?.toUpperCase() || c.indexInitial?.name?.toUpperCase() || '-'}
            </TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Valor inicial </TableMobileTitle>
            <TableMobileContent>
              {convertCentsToWhole(
                c.financialContract?.initialValue,
                c.financialContract?.coin?.title
              )}
            </TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Valor atual </TableMobileTitle>
            <TableMobileContent>
              {convertCentsToWhole(
                c.financialContract?.currentValue,
                c.financialContract?.coin?.title
              ) || '-'}
            </TableMobileContent>
          </TableMobileItem>

          <TableMobileItem>
            <TableMobileTitle>Mês reajuste </TableMobileTitle>
            <TableMobileContent>
              {convertReadjustmentMonth(c.financialContract?.readjustmentMonth)}
            </TableMobileContent>
          </TableMobileItem>

          <HiddenForPermission keys={'FIND_CONTRACT'}>
            <TableMobileButton onClick={() => goToDetails(c.uuid)}>Ver detalhes</TableMobileButton>
          </HiddenForPermission>
        </TableMobileCell>
      );
    });

  return (
    <MobileTableConteiner>
      {!loading ? contractsList : <SkeletonMobile numbersOfColumns={10} />}
      <PaginationMobile count={count} page={page} rowsPerPage={offset} handlePage={handlePage} />
    </MobileTableConteiner>
  );
}
