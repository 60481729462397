import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  goToAdmin,
  goToBack,
  goToDatailsUser,
  goToUsersList
} from '../../../../../../router/coordinator';
import { clearAlertMessage, fullname, noRepeatArray } from '../../../../../../utils';
import {
  CategoryContainer,
  Content
} from '../../RegisterNewUserPage/permissions/ChoicePermissionsPage/styled';
import { translateCategoryPermission } from '../../../../../../utils/translate';
import { Button, Divider, Grid, MenuItem, Switch, TextField } from '@mui/material';
import { BasePage } from '../../../../../../components/BaseMain';
import { AlertMessage, Loading, Modal, TitlePages } from '../../../../../../components';
import { useForm } from '../../../../../../hooks/useForm';
import { Container, SelectCompany } from './styled';
import {
  changeUserPermissions,
  findUser,
  listAccessUserCompanies,
  listAllPermissions,
  listUserPermissionsForCompany
} from '../../../../../../services';
import { GENERIC_ERROR } from '../../../../../../utils/constantes';
import { Offices } from '../../../../../../utils/enums';
import { CustomFormControlLabel } from '../../../../../../components/CustomFormControlLabel';

export function PermissionPage() {
  const [user, setUser] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [permissionsChoice, setPermissionsChoice] = useState({});
  const [allPermissionsSelected, setAllPermissionsSelected] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [form, onChange, clearForm] = useForm({ company: '' });
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = async () => {
    try {
      setLoading(true);
      handleClose();

      await changeUserPermissions(uuid, form.company, permissionsChoice);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'Permissões alteradas com sucesso.',
        severity: 'success'
      }));

      clearForm();
      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };
  // ---

  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Usuários', functionCoordinator: () => goToUsersList(navigate) },
    { title: 'Detalhes', functionCoordinator: () => goToDatailsUser(navigate, uuid) },
    { title: 'Permissões' }
  ];

  useEffect(async () => await init(), []);
  useEffect(async () => await list(), [form]);

  const init = async () => {
    try {
      setLoading(true);
      setPermissionsChoice({});
      setAllPermissionsSelected(false);

      const result = await Promise.all([listAccessUserCompanies(uuid), findUser(uuid)]).then(
        (res) => ({
          company: res[0],
          user: res[1]
        })
      );
      setCompanies(result.company);
      setUser(result.user);

      let isAdmin = false;
      if (result.user?.office?.title === Offices.ADMIN) {
        isAdmin = true;
      }

      const permissionsList = await listAllPermissions(isAdmin);
      setPermissions(permissionsList);

      initListOfCategories(permissionsList);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  const list = async () => {
    try {
      if (!form.company) {
        return;
      }

      setLoading(true);
      setPermissionsChoice({});
      setAllPermissionsSelected(false);

      const result = await listUserPermissionsForCompany(user.uuid, form.company);
      initListOfPermissions(result);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  /**
   * Cria uma lista com as categorias existentes
   */
  const initListOfCategories = (permissions) => {
    const categories = [];
    permissions.forEach((permission) => {
      categories.push(permission.category);
    });
    setCategories(noRepeatArray(categories));
  };

  /**
   * Cria o objeto no estado com todos as permissões e seu valor
   */
  const initListOfPermissions = (permissionsUser) => {
    let permissionsList = {};

    permissions.forEach((permission) => {
      const haveAccess = permissionsUser.some((permissionKey) => {
        return permissionKey === permission.key;
      });
      permissionsList = { ...permissionsList, [permission.key]: haveAccess };
    });

    setPermissionsChoice(permissionsList);

    const allSelected = Object.values(permissionsList).every((value) => value === true);
    const noneSelected = Object.values(permissionsList).every((value) => value === false);

    if (allSelected) {
      setAllPermissionsSelected(true);
    } else if (noneSelected) {
      setAllPermissionsSelected(false);
    } else {
      setAllPermissionsSelected(null);
    }
  };

  /**
   * Marcar ou desmarca todos o toggles
   * @param {*} value - verdadeiro ou falso
   */
  const changeAllToggles = (value) => {
    let newValues = {};
    for (const key in permissionsChoice) {
      newValues = { ...newValues, [key]: value };
    }

    setPermissionsChoice(newValues);
    setAllPermissionsSelected(value);
  };

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setPermissionsChoice({ ...permissionsChoice, [name]: checked });
    setAllPermissionsSelected(null);
  };

  const listPermissions = (list, category) => {
    return (
      <CategoryContainer>
        <p>{translateCategoryPermission(category).toUpperCase()}</p>
        <div>
          {list.map((permission) => (
            <CustomFormControlLabel
              key={permission.id}
              control={
                <Switch
                  checked={permissionsChoice[permission.key]}
                  name={permission.key}
                  onChange={handleToggle}
                />
              }
              label={permission.description?.toTitleCase()}
            />
          ))}
        </div>
      </CategoryContainer>
    );
  };

  /**
   * A lista de empresas é divida em duas colunas
   * 1° coluna
   */
  function ListPermissionsFirthColumn() {
    const halfListLength = Math.round(categories.length / 2);
    const list = categories.slice(0, halfListLength);

    const result = [];
    for (const category of list) {
      const permissionsList = permissions.filter((c) => c.category === category);
      result.push(listPermissions(permissionsList, category));
    }

    return result;
  }

  /**
   * 2° coluna
   */
  function ListPermissionsSecondColumn() {
    const halfListLength = Math.round(categories.length / 2);
    const list = categories.slice(halfListLength);

    const result = [];
    for (const category of list) {
      const permissionsList = permissions.filter((c) => c.category === category);
      result.push(listPermissions(permissionsList, category));
    }

    return result;
  }

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <Loading loading={loading} />
      <Grid container>
        <AlertMessage
          title={alertMessage.title}
          message={alertMessage.message}
          severity={alertMessage.severity}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Container>
        <SelectCompany container>
          <Grid item xs={12} lg={6}>
            <label id="label-company">Para qual empresa deseja modificar as permissões?</label>
            <TextField select value={form.company} onChange={onChange} name={'company'}>
              {companies.map((company) => (
                <MenuItem key={company.uuid} value={company.uuid}>
                  {company.name.toTitleCase()}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </SelectCompany>

        {form.company && (
          <>
            <Divider />

            <TitlePages
              title={`Escolha as permissões que o usuário ${fullname(
                user.name,
                user.lastName
              )} terá nessa empresa`}
              description={
                'As alterações só serão salvas após apertar o botão "Salvar", qualquer alteração feita sem essa confirmação será ignorada pelo sistema.'
              }
              toHideDivider={true}
            />

            <Content container>
              <div id="button-group">
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => changeAllToggles(true)}
                  disabled={allPermissionsSelected}
                >
                  Marcar todas as permissões
                </Button>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => changeAllToggles(false)}
                  disabled={allPermissionsSelected === false}
                >
                  Desmarcar todas as permissões
                </Button>
              </div>

              <Grid item className="column" xs={12} lg={6}>
                <ListPermissionsFirthColumn />
              </Grid>
              <Grid item className="column" xs={12} lg={6}>
                <ListPermissionsSecondColumn />
              </Grid>

              <Grid container className={'buttonGroup twoButtons'}>
                <Button variant="outlined" onClick={() => goToBack(navigate)}>
                  Voltar
                </Button>
                <Button onClick={handleOpen}>Salvar</Button>
              </Grid>
            </Content>
          </>
        )}
      </Container>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title={'Mudar permissões do usuário'}
        text={`Tem certeza que deseja mudar as permissões do usuário ${fullname(
          user.name,
          user.lastName
        )} nesta empresa?`}
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
