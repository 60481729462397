import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu } from '@mui/material';
import React from 'react';
import { Container } from './styled';
import { useUpdateState } from '../../../hooks/useUpdateState';

export default function MenuContainer({ children }) {
  const [{ anchorEl }, setStates] = useUpdateState({ anchorEl: null });

  const handleClick = (event) => {
    setStates({ anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setStates({ anchorEl: null });
  };

  return (
    <Container>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {children}
      </Menu>
    </Container>
  );
}
