import styled from 'styled-components';
import { breakpoints } from '../../../theme/breakpoints';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  grid-auto-rows: 0.6rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
  grid-auto-rows: minmax(0.6rem, auto);

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(auto-fill, 15rem);
  }
`;
