import { CategoriesPermission, Status } from './enums';

/**
 * Traduz o status vindo do banco
 * @param {*} status
 * @returns
 */
export const translateStatus = (status) => {
  switch (status) {
    case Status.ACTIVE:
      return 'Ativo';

    case Status.INACTIVE:
      return 'Inativo';

    case Status.PENDING:
      return 'Pendente';

    case Status.BLOCKED:
      return 'Bloqueado';

    case Status.DELETED:
      return 'Deletado';

    case Status.ALL:
      return 'Todos';

    default:
      return status;
  }
};

/**
 * Traduz as categorias das permissões
 * @param {*} category
 * @returns
 */
export const translateCategoryPermission = (category) => {
  switch (category) {
    case CategoriesPermission.USER:
      return 'Usuário';
    case CategoriesPermission.COMPANY:
      return 'Empresa';
    case CategoriesPermission.CONTRACT:
      return 'Contrato';
    case CategoriesPermission.ADDITIONAL_CONTRACT:
      return 'Contrato Adicional';
    case CategoriesPermission.OTHER:
      return 'Outro';
    case CategoriesPermission.CONFIG:
      return 'Configuração';
    case CategoriesPermission.ADMIN:
      return 'Administração';
    case CategoriesPermission.DASHBOARD:
      return 'Dashboard';
    case CategoriesPermission.SECTORS:
      return 'Setor';
    case CategoriesPermission.PROVIDER:
      return 'Fornecedor';
    case CategoriesPermission.INDEXES:
      return 'Índice';
    case CategoriesPermission.COINS:
      return 'Moedas';
    case CategoriesPermission.SECURITY:
      return 'Segurança';
    case CategoriesPermission.FINANCIAL:
      return 'Financeiro';
    case CategoriesPermission.INDUSTRY:
      return 'Setor de atuação';
    default:
      return category;
  }
};
