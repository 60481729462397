import styled from 'styled-components';

export const DeleteContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  pointer-events: none;
  gap: 0.4rem;
  color: red;

  span {
    padding-top: 0.25rem;
  }
`;
