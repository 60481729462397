import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ChooseCompany } from '../pages/ChooseCompanyPage';
import { Terms } from '../pages/TermsPage';
import {
  Login,
  Signup,
  ForgotPassword,
  CreatePassword,
  AccountCreated,
  RedefinePassword,
  DefinePassword
} from '../pages/credentials';
import {
  Home,
  ListExpiringContracts,
  ListOfContractFines,
  ListOfAddedContracts,
  Financial,
  AddContract,
  ListAllContracts,
  DetailsContractPage,
  VisualizeContractPage,
  DetailsFinancialContractPage,
  ListAditionalsContractsPage,
  AddAditionalContractPage,
  NotFoundPage
} from '../pages/main';
import {
  TermsAndGuidelines,
  RedefinePassword as RedefinePasswordMenu,
  EditAccount,
  DetailsTermsAndGuidelines
} from '../pages/main/menuOptions';
import {
  ChoiceCompanyPermiossionPage,
  CompanyInformationsPage,
  PainelAdmin,
  RegisterNewUser,
  UsersListPage,
  ChoicePermissions,
  ConcludedPage,
  UserAddedContactList,
  CompanyPermissionsPage,
  PermissionPage,
  EditCompanyInformationsPage,
  ListCompaniesPage,
  CreateNewCompanyPage,
  DetailsUserPage,
  ListSegmentPage,
  CreateSegmentPage
} from '../pages/main/admin';
import { CreateSectorPage, EditSectorPage, ListSectorsPage } from '../pages/main/admin/sectors';
import {
  ListIndexPage,
  IndexHistoryPage,
  CreateIndexPage,
  CreateIndexHistoryPage,
  EditIndexHistoryPage
} from '../pages/main/admin/indexes';
import {
  CoinHistoryPage,
  ListCoinPage,
  CreateCoinPage,
  CreateCoinHistoryPage,
  EditCoinPage
} from '../pages/main/admin/coins';
import {
  ProvidersListPage,
  EditProviderInformationsPage,
  ProviderInformationsPage,
  CreateNewProviderPage
} from '../pages/main/admin/providers';
import {
  ListIndustryPage,
  CreateIndustryPage,
  EditIndustryPage
} from '../pages/main/admin/industry';
import { PrivateRoute } from '../components/PrivateRoute';
import { VisualizeAdditionalContractPage } from '../pages/main/contracts/ListAditionalsContractsPage/VisualizeAdditionalContractPage';
import { EditContractPage } from '../pages/main/contracts/EditContractPage';
import { WithVariableValuesPage } from '../pages/main/contracts/WithVariableValuesPage';
import { AllValuesContracts } from '../pages/main/contracts/WithVariableValuesPage/AllValuesContracts';
import { AddValueContract } from '../pages/main/contracts/WithVariableValuesPage/AddValueContract';
import { ListOfficesPage } from '../pages/main/admin/template';
import { EditTemplatePage } from '../pages/main/admin/template/EditTemplatePage';
import { EditUserPage } from '../pages/main/admin/user/EditUserPage';
import { EditSegmentPage } from '../pages/main/admin/segments/EditSegmentPage';
import { CreateHelpFilePage } from '../pages/main/admin/help';
import { HelpFilePage } from '../pages/main/HelpFilePage';
import { EditAdditionalContractPage } from '../pages/main/contracts/ListAditionalsContractsPage/EditAdditionalContractsPage';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export default function Router() {
  const reCaptchaKey = process.env.REACT_APP_PUBLIC_RECAPTCHA_KEY;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="" element={<Home />} />
          <Route path="escolher-empresa" element={<ChooseCompany />} />
          <Route path="termos" element={<Terms />} />
          <Route path="ajuda" element={<HelpFilePage />} />
        </Route>
        <Route path="credenciais">
          <Route
            path="entrar"
            element={
              <GoogleReCaptchaProvider useEnterprise reCaptchaKey={reCaptchaKey}>
                <Login />
              </GoogleReCaptchaProvider>
            }
          />
          <Route path="cadastro" element={<Signup />} />
          <Route path="cadastrar-senha" element={<CreatePassword />} />
          <Route path="conta-criada" element={<AccountCreated />} />
          <Route path="esqueci-senha" element={<ForgotPassword />} />
          <Route path="redefinir-senha" element={<RedefinePassword />} />
          <Route path="definir-senha" element={<DefinePassword />} />
        </Route>
        <Route path="menu">
          <Route path="editar-conta" element={<EditAccount />} />
          <Route path="redefinir-senha" element={<RedefinePasswordMenu />} />
          <Route path="termos-e-diretrizes">
            <Route path="" element={<TermsAndGuidelines />} />
            <Route path="detalhes/:uuid" element={<DetailsTermsAndGuidelines />} />
          </Route>
        </Route>
        <Route path="contratos">
          <Route
            path=""
            element={
              <PrivateRoute
                Page={ListAllContracts}
                permissionKey={['LIST_CONTRACT', 'CREATE_CONTRACT']}
              />
            }
          />
          <Route
            path=":uuidContract/contratos-adicionais/:uuidAdditionalContract/editar"
            element={
              <PrivateRoute
                Page={EditAdditionalContractPage}
                permissionKey={'EDIT_ADDITIONAL_CONTRACT'}
              />
            }
          />
          <Route
            path="detalhes/:uuid"
            element={<PrivateRoute Page={DetailsContractPage} permissionKey={'FIND_CONTRACT'} />}
          />
          <Route
            path="detalhes/:uuid/financeiro"
            element={
              <PrivateRoute
                Page={DetailsFinancialContractPage}
                permissionKey={'FIND_FINANCIAL_CONTRACT'}
              />
            }
          />
          <Route
            path="detalhes/:uuid/contratos-adicionais"
            element={
              <PrivateRoute
                Page={ListAditionalsContractsPage}
                permissionKey={['LIST_ADDITIONALS_CONTRACT', 'CREATE_ADDITIONAL_CONTRACT']}
              />
            }
          />
          <Route
            path="cadastrar"
            element={<PrivateRoute Page={AddContract} permissionKey={'CREATE_CONTRACT'} />}
          />
          <Route
            path=":uuid/editar"
            element={<PrivateRoute Page={EditContractPage} permissionKey={'EDIT_CONTRACT'} />}
          />
          <Route
            path="detalhes/:uuid/contratos-adicionais/cadastrar"
            element={
              <PrivateRoute
                Page={AddAditionalContractPage}
                permissionKey={'CREATE_ADDITIONAL_CONTRACT'}
              />
            }
          />
          <Route
            path="detalhes/:uuid/visualizar"
            element={
              <PrivateRoute Page={VisualizeContractPage} permissionKey={'FIND_FILE_CONTRACT'} />
            }
          />
          <Route
            path="detalhes/:uuid/contratos-adicionais/visualizar"
            element={
              <PrivateRoute
                Page={VisualizeAdditionalContractPage}
                permissionKey={'FIND_FILE_ADDITIONAL_CONTRACT'}
              />
            }
          />

          <Route
            path="expirando"
            element={
              <PrivateRoute
                Page={ListExpiringContracts}
                permissionKey={'LIST_30_DAYS_CONTRACT_EXPIRING'}
              />
            }
          />
          <Route
            path="multa-rescisoria"
            element={
              <PrivateRoute
                Page={ListOfContractFines}
                permissionKey={'LIST_TERMINATION_FINE_CONTRACT'}
              />
            }
          />
          <Route
            path="adicionados"
            element={
              <PrivateRoute
                Page={ListOfAddedContracts}
                permissionKey={'LIST_30_DAYS_CONTRACT_ADDED'}
              />
            }
          />
          <Route
            path="valores-variáveis"
            element={
              <PrivateRoute
                Page={WithVariableValuesPage}
                permissionKey="LIST_CONTRACTS_WITH_PENDING_VALUES"
              />
            }
          />
          <Route
            path="valores-variáveis/:uuid/valores"
            element={
              <PrivateRoute Page={AllValuesContracts} permissionKey="LIST_VALUES_OF_CONTRACT" />
            }
          />
          <Route
            path="valores-variáveis/:uuid/adicionar"
            element={<PrivateRoute Page={AddValueContract} permissionKey="ADDED_VALUE_CONTRACT" />}
          />
        </Route>
        <Route path="financeiro">
          <Route
            path=""
            element={<PrivateRoute Page={Financial} permissionKey={'LIST_FINANCIAL_CONTRACT'} />}
          />
        </Route>
        <Route path="administrativo">
          <Route path="" element={<PrivateRoute Page={PainelAdmin} isAdmin={true} />} />
          <Route path="empresa">
            <Route
              path=":uuid/detalhes"
              element={<PrivateRoute Page={CompanyInformationsPage} isAdmin={true} />}
            />
            <Route
              path="todas"
              element={
                <PrivateRoute
                  Page={ListCompaniesPage}
                  isAdmin={true}
                  permissionKey={['CREATE_COMPANY', 'LIST_ACCESS_COMPANY']}
                />
              }
            />
            <Route
              path="criar"
              element={
                <PrivateRoute
                  Page={CreateNewCompanyPage}
                  isAdmin={true}
                  permissionKey={'CREATE_COMPANY'}
                />
              }
            />
            <Route
              path=":uuid/editar"
              element={
                <PrivateRoute
                  Page={EditCompanyInformationsPage}
                  isAdmin={true}
                  permissionKey={'EDIT_COMPANY'}
                />
              }
            />
          </Route>
          <Route path="usuarios">
            <Route
              path=""
              element={
                <PrivateRoute
                  Page={UsersListPage}
                  isAdmin={true}
                  permissionKey={['LIST_USER_ADMIN', 'REGISTER_USER_ADMIN']}
                />
              }
            />
            <Route
              path=":uuid/detalhes"
              element={
                <PrivateRoute
                  Page={DetailsUserPage}
                  isAdmin={true}
                  permissionKey={'FIND_USER_ADMIN'}
                />
              }
            />
            <Route
              path="registrar"
              element={
                <PrivateRoute
                  Page={RegisterNewUser}
                  isAdmin={true}
                  permissionKey={'REGISTER_USER_ADMIN'}
                />
              }
            />
            <Route
              path="registrar/:uuid/permissao-empresas"
              element={
                <PrivateRoute
                  Page={ChoiceCompanyPermiossionPage}
                  isAdmin={true}
                  permissionKey={'REGISTER_USER_ADMIN'}
                />
              }
            />
            <Route
              path="registrar/permissao/:uuid"
              element={
                <PrivateRoute
                  Page={ChoicePermissions}
                  isAdmin={true}
                  permissionKey={'REGISTER_USER_ADMIN'}
                />
              }
            />
            <Route
              path="registrar/concluido"
              element={<PrivateRoute Page={ConcludedPage} isAdmin={true} />}
            />

            <Route
              path=":uuid/contratos-adicionados"
              element={
                <PrivateRoute
                  Page={UserAddedContactList}
                  isAdmin={true}
                  permissionKey={'LIST_CONTRACTS_USER'}
                />
              }
            />
            <Route
              path=":uuid/permissoes-empresa"
              element={
                <PrivateRoute
                  Page={CompanyPermissionsPage}
                  isAdmin={true}
                  permissionKey={'ACCESS_COMPANY'}
                />
              }
            />
            <Route
              path=":uuid/permissoes"
              element={
                <PrivateRoute
                  Page={PermissionPage}
                  isAdmin={true}
                  permissionKey={'ALTER_PERMISSIONS'}
                />
              }
            />
            <Route
              path=":uuid/editar"
              element={
                <PrivateRoute Page={EditUserPage} isAdmin={true} permissionKey="EDIT_ADMIN_USER" />
              }
            />
          </Route>
          <Route path="fornecedores">
            <Route
              path=""
              element={
                <PrivateRoute
                  isAdmin={true}
                  Page={ProvidersListPage}
                  permissionKey={['CREATE_PROVIDER', 'LIST_PROVIDERS']}
                />
              }
            />
            <Route
              path="criar"
              element={
                <PrivateRoute
                  isAdmin={true}
                  Page={CreateNewProviderPage}
                  permissionKey="CREATE_PROVIDER"
                />
              }
            />
            <Route
              path=":uuid/detalhes"
              element={
                <PrivateRoute
                  isAdmin={true}
                  Page={ProviderInformationsPage}
                  permissionKey="FIND_PROVIDER"
                />
              }
            />
            <Route
              path=":uuid/editar"
              element={
                <PrivateRoute
                  isAdmin={true}
                  Page={EditProviderInformationsPage}
                  permissionKey="EDIT_PROVIDER"
                />
              }
            />
          </Route>
          <Route path="indices">
            <Route
              path=""
              element={
                <PrivateRoute isAdmin={true} Page={ListIndexPage} permissionKey={'LIST_INDEX'} />
              }
            />
            <Route
              path="cadastrar"
              element={
                <PrivateRoute isAdmin={true} Page={CreateIndexPage} permissionKey="CREATE_INDEX" />
              }
            />
            <Route
              path=":uuid/historico"
              element={
                <PrivateRoute
                  isAdmin={true}
                  Page={IndexHistoryPage}
                  permissionKey="LIST_INDEX_HISTORY"
                />
              }
            />
            <Route
              path=":uuid/cadastrar/historico"
              element={
                <PrivateRoute
                  isAdmin={true}
                  Page={CreateIndexHistoryPage}
                  permissionKey="CREATE_HISTORY_INDEX"
                />
              }
            />
            <Route
              path="historico/:uuid/editar"
              element={
                <PrivateRoute
                  isAdmin={true}
                  Page={EditIndexHistoryPage}
                  permissionKey="EDIT_HISTORY_INDEX"
                />
              }
            />
          </Route>
          <Route path="moeda">
            <Route
              path=""
              element={
                <PrivateRoute isAdmin={true} Page={ListCoinPage} permissionKey={'LIST_COINS'} />
              }
            />
            <Route
              path="cadastrar"
              element={
                <PrivateRoute isAdmin={true} Page={CreateCoinPage} permissionKey={'CREATE_COIN'} />
              }
            />
            <Route
              path=":uuid/editar"
              element={
                <PrivateRoute isAdmin={true} Page={EditCoinPage} permissionKey="EDIT_COIN" />
              }
            />
            <Route
              path=":uuid/historico"
              element={<PrivateRoute isAdmin={true} Page={CoinHistoryPage} />}
            />
            <Route
              path=":uuid/cadastrar/historico"
              element={<PrivateRoute isAdmin={true} Page={CreateCoinHistoryPage} />}
            />
          </Route>
          <Route path="setores">
            <Route
              path=""
              element={
                <PrivateRoute
                  Page={ListSectorsPage}
                  isAdmin={true}
                  permissionKey={['LIST_SECTORS', 'CREATE_SECTOR']}
                />
              }
            />
            <Route
              path=":uuid/editar"
              element={
                <PrivateRoute Page={EditSectorPage} isAdmin={true} permissionKey={'EDIT_SECTORS'} />
              }
            />
            <Route
              path="cadastrar"
              element={
                <PrivateRoute
                  Page={CreateSectorPage}
                  isAdmin={true}
                  permissionKey={'CREATE_SECTOR'}
                />
              }
            />
          </Route>
          <Route path="setores-empresa">
            <Route
              path=""
              element={
                <PrivateRoute
                  Page={ListIndustryPage}
                  isAdmin={true}
                  permissionKey={['LIST_INDUSTRY', 'CREATE_INDUSTRY']}
                />
              }
            />
            <Route
              path="cadastrar"
              element={
                <PrivateRoute
                  Page={CreateIndustryPage}
                  isAdmin={true}
                  permissionKey="CREATE_INDUSTRY"
                />
              }
            />
            <Route
              path=":uuid/editar"
              element={
                <PrivateRoute
                  Page={EditIndustryPage}
                  isAdmin={true}
                  permissionKey="EDIT_INDUSTRY"
                />
              }
            />
          </Route>
          <Route path="segmentos">
            <Route
              path=""
              element={
                <PrivateRoute
                  Page={ListSegmentPage}
                  isAdmin={true}
                  permissionKey={['LIST_SEGMENT', 'CREATE_SEGMENT']}
                />
              }
            />
            <Route
              path="cadastrar"
              element={
                <PrivateRoute
                  Page={CreateSegmentPage}
                  isAdmin={true}
                  permissionKey="CREATE_SEGMENT"
                />
              }
            />
            <Route
              path=":uuid/editar"
              element={
                <PrivateRoute Page={EditSegmentPage} isAdmin={true} permissionKey="EDIT_SEGMENT" />
              }
            />
          </Route>
          <Route path="template">
            <Route
              path=""
              element={
                <PrivateRoute Page={ListOfficesPage} isAdmin={true} permissionKey="EDIT_TEMPLATE" />
              }
            />
            <Route
              path=":uuid/editar"
              element={
                <PrivateRoute
                  Page={EditTemplatePage}
                  isAdmin={true}
                  permissionKey="EDIT_TEMPLATE"
                />
              }
            />
          </Route>
          <Route path="arquivo-ajuda">
            <Route
              path=""
              element={
                <PrivateRoute
                  Page={CreateHelpFilePage}
                  isAdmin={true}
                  permissionKey="CREATE_HELP_FILE"
                />
              }
            />
          </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
