import { useNavigate, useParams } from 'react-router-dom';
import { BasePage } from '../../../../../../components/BaseMain';
import {
  goToAdmin,
  goToBack,
  goToDatailsUser,
  goToUsersList
} from '../../../../../../router/coordinator';
import { useEffect, useState } from 'react';
import { AlertMessage, Loading, Modal, TitlePages } from '../../../../../../components';
import { Content } from './styled';
import { Button, Grid, Switch } from '@mui/material';
import { clearAlertMessage, fullname } from '../../../../../../utils';
import {
  findUser,
  listAccessCompaniesNoPaginate,
  listAccessUserCompanies,
  permissionAccessToCompany
} from '../../../../../../services';
import { GENERIC_ERROR } from '../../../../../../utils/constantes';
import { CustomFormControlLabel } from '../../../../../../components/CustomFormControlLabel';

export function CompanyPermissionsPage() {
  const [user, setUser] = useState({});
  const [companiesChoice, setCompaniesChoice] = useState({});
  const [companies, setCompanies] = useState([]);
  const [allCompaniesSelected, setAllCompaniesSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = () => {
    saveChanges();
  };
  // ---

  const navigate = useNavigate();
  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Usuários', functionCoordinator: () => goToUsersList(navigate) },
    { title: 'Detalhes', functionCoordinator: () => goToDatailsUser(navigate, uuid) },
    { title: 'Permissão de acesso as empresas' }
  ];

  useEffect(async () => await init(), []);

  const init = async () => {
    try {
      setLoading(true);

      const result = await Promise.all([
        listAccessCompaniesNoPaginate(true),
        findUser(uuid),
        listAccessUserCompanies(uuid)
      ]).then((res) => ({
        companies: res[0],
        user: res[1],
        access_companies: res[2]
      }));

      setCompanies(result.companies);
      setUser(result.user);

      const uuidsCompanyAccess = result.access_companies?.map((c) => c.uuid);

      initListOfCompanies(uuidsCompanyAccess, result.companies);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  /**
   * Cria o objeto no estado com todos os ids das
   * empresas e o valor se ela foi escolhida ou não
   */
  const initListOfCompanies = (companiesAccess, companies) => {
    let companiesList = {};

    companies?.forEach((company) => {
      const haveAccess = companiesAccess?.some?.((uuidCompany) => uuidCompany === company.uuid);
      companiesList = { ...companiesList, [company.uuid]: haveAccess };
    });
    setCompaniesChoice(companiesList);

    const allSelected = Object.values(companiesList).every((value) => value === true);
    const noneSelected = Object.values(companiesList).every((value) => value === false);

    if (allSelected) {
      setAllCompaniesSelected(true);
    } else if (noneSelected) {
      setAllCompaniesSelected(false);
    } else {
      setAllCompaniesSelected(null);
    }
  };

  function RenderListCompanies({ list }) {
    return list.map((company) => {
      return (
        <CustomFormControlLabel
          key={company.uuid}
          control={
            <Switch
              checked={companiesChoice[company.uuid]}
              name={company.uuid}
              onChange={handleToggle}
            />
          }
          label={company.name.toTitleCase()}
        />
      );
    });
  }

  /**
   * A lista de empresas é divida em duas colunas
   * 1° coluna
   */
  function ListCompaniesFirthColumn() {
    const halfListLength = Math.round(companies.length / 2);
    const list = companies.slice(0, halfListLength);

    return <RenderListCompanies list={list} />;
  }

  /**
   * A lista de empresas é divida em duas colunas
   * 2° coluna
   */
  function ListCompaniesSecondColumn() {
    const halfListLength = Math.round(companies.length / 2);
    const list = companies.slice(halfListLength);

    return <RenderListCompanies list={list} />;
  }

  /**
   * Marcar ou desmarca todos o toggles
   * @param {*} value - verdadeiro ou falso
   */
  const changeAllToggles = (value) => {
    let newValues = {};
    for (const key in companiesChoice) {
      newValues = { ...newValues, [key]: value };
    }

    setCompaniesChoice(newValues);
    setAllCompaniesSelected(value);
  };

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setCompaniesChoice({ ...companiesChoice, [name]: checked });
    setAllCompaniesSelected(null);
  };

  const saveChanges = async () => {
    try {
      setLoading(true);
      handleClose();

      await permissionAccessToCompany(uuid, companiesChoice);

      setAlertMessage((prev) => ({
        ...prev,
        title: 'O acesso as empresas foi modificado com sucesso.',
        severity: 'success'
      }));

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages
        title={`Escolha as empresas que o usuário ${fullname(
          user.name,
          user.lastName
        )} terá acesso`}
        toHideDivider={true}
      />

      <Loading loading={loading} />

      <AlertMessage
        message={alertMessage.message}
        severity={alertMessage.severity}
        title={alertMessage.title}
        onClose={() => clearAlertMessage(setAlertMessage)}
      />

      <Content container>
        <div id="button-group">
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => changeAllToggles(true)}
            disabled={allCompaniesSelected}
          >
            Marcar todas as empresas
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => changeAllToggles(false)}
            disabled={allCompaniesSelected === false}
          >
            Desmarcar todas as empresas
          </Button>
        </div>

        <Grid item className="column" xs={12} lg={6}>
          <ListCompaniesFirthColumn />
        </Grid>

        <Grid item className="column" xs={12} lg={6}>
          <ListCompaniesSecondColumn />
        </Grid>

        <Grid container className={'buttonGroup twoButtons'}>
          <Button variant="outlined" onClick={() => goToBack(navigate)}>
            Voltar
          </Button>
          <Button onClick={handleOpen}>Salvar</Button>
        </Grid>
      </Content>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title={'Mudar permissões de acesso às empresa do usuário'}
        text={`Tem certeza que deseja mudar o acesso do usuário as empresas? `}
        warning={
          'Ele iniciará com nenhuma permissão nessa empresa,  para mudar vá em "modificar permissões" na tela de detalhes do usuários.'
        }
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
