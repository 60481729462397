import { TextField, Autocomplete, Box } from '@mui/material';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDebounce } from 'use-debounce';
import { Document, ItemContent } from './styled';

export default function AutocompleteSearch({
  value,
  label,
  optionLabel = ['uuid'],
  formatLabel = null,
  optionValue = 'uuid',
  getListDataCallback,
  onChange,
  name,
  ...props
}) {
  const [inputValue, setInputValue] = useState('');
  const [inputValueDebounced] = useDebounce(inputValue, 300);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);

  const isCnpj = (document) => document?.length === 14;

  const formatDocument = (document) => {
    if (!document) {
      return '';
    }

    if (isCnpj(document)) {
      return document
        .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        .toUpperCase();
    }

    return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4').toUpperCase();
  };

  const autocompleteOptions = useMemo(
    () =>
      listData?.map?.((itemData) => {
        const label = optionLabel.map((label) => itemData?.[label]).join(' ');

        return {
          label: label,
          value: itemData?.[optionValue],
          document: formatDocument(itemData?.document),
          isCnpj: isCnpj(itemData?.document)
        };
      }),
    [listData, optionLabel, optionValue]
  );

  const autocompleteOnChange = (_e, newValue) => {
    setInputValue('');
    onChange?.({
      target: { name, value: newValue?.value }
    });
  };

  const getListData = useCallback(async () => {
    if (getListDataCallback) {
      const data = await getListDataCallback();

      if (data) {
        setListData(data);
      }
    }
  }, [getListDataCallback, inputValueDebounced, value?.value]);

  const autocompleteValue = useMemo(() => {
    const item = autocompleteOptions.find((option) => option.value === value);
    return item
      ? { ...item, label: formatLabel?.(item?.label) ?? item?.label?.toTitleCase?.() }
      : null;
  }, [value, autocompleteOptions]);

  useEffect(() => {
    try {
      setLoading(true);
      getListData();
    } finally {
      setLoading(false);
    }
  }, [getListData, value?.value]);

  return (
    <Autocomplete
      {...props}
      options={autocompleteOptions}
      value={!loading ? autocompleteValue : 'Carregando...'}
      isOptionEqualToValue={(option, selectedOption) =>
        option?.value.toUpperCase?.() === selectedOption?.value?.toUpperCase?.()
      }
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => <TextField {...params} {...props} label={label} />}
      noOptionsText="Sem opções"
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.value}>
          <ItemContent>
            {formatLabel?.(option.label) ?? option.label?.toTitleCase?.()}
            {option?.document && (
              <Document>
                <b>{option?.isCnpj ? 'CNPJ' : 'CPF'}:</b> {option?.document}
              </Document>
            )}
          </ItemContent>
        </Box>
      )}
      name={name}
      onChange={autocompleteOnChange}
    />
  );
}
