import {
  HiddenForPermission,
  PaginationMobile,
  SkeletonMobile,
  TargetStatus
} from '../../../../components';
import {
  MobileTableConteiner,
  TableMobileButton,
  TableMobileCell,
  TableMobileContent,
  TableMobileItem,
  TableMobileTitle
} from '../../../../components/MobileTable';
import { goToDatailsFinancialContract } from '../../../../router/coordinator';
import { convertReadjustmentMonth } from '../../../../utils/convert';

export function MobileTable({ contracts, navigate, loading, count, page, offset, handlePage }) {
  const contractsList = contracts?.map((c) => {
    return (
      <TableMobileCell key={c.contract?.id}>
        <TableMobileItem>
          <TableMobileTitle>Id</TableMobileTitle>
          <TableMobileContent>{c.contract?.id}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Status</TableMobileTitle>
          <TableMobileContent>
            <TargetStatus status={c.contract?.status} />
          </TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Débito</TableMobileTitle>
          <TableMobileContent>{c.debit?.toMask('debit-credit')}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Crédito</TableMobileTitle>
          <TableMobileContent>{c.credit?.toMask('debit-credit')}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Moeda</TableMobileTitle>
          <TableMobileContent>{c.coin?.title?.toTitleCase()}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Parcelas totais</TableMobileTitle>
          <TableMobileContent>{c.totalInstallments}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Parcelas pagas</TableMobileTitle>
          <TableMobileContent>{c.installmentsPaid}</TableMobileContent>
        </TableMobileItem>

        <TableMobileItem>
          <TableMobileTitle>Mês reajuste</TableMobileTitle>
          <TableMobileContent>{convertReadjustmentMonth(c.readjustmentMonth)}</TableMobileContent>
        </TableMobileItem>

        <HiddenForPermission keys={'FIND_FINANCIAL_CONTRACT'}>
          <TableMobileButton
            onClick={() => goToDatailsFinancialContract(navigate, c.contract?.uuid)}
          >
            Ver detalhes
          </TableMobileButton>
        </HiddenForPermission>
      </TableMobileCell>
    );
  });

  return (
    <MobileTableConteiner>
      {!loading ? contractsList : <SkeletonMobile numbersOfColumns={10} />}
      <PaginationMobile count={count} page={page} rowsPerPage={offset} handlePage={handlePage} />
    </MobileTableConteiner>
  );
}
