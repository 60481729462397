import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ViewListIcon from '@mui/icons-material/ViewList';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { ButtonGroup, ButtonIcon, Title } from './styled';
import { BasePage } from '../../../../components/BaseMain';
import {
  goToBack,
  goToListAdicionalsContract,
  goToListAllContracts,
  goToVisualizeContract,
  goToDatailsContract
} from '../../../../router/coordinator';
import {
  convertCentsToWhole,
  convertNewValueCoin,
  convertReadjustmentMonth,
  convertReadjustmentPeriodicity
} from '../../../../utils/convert';
import {
  AlertMessage,
  ButtonPage,
  HiddenForPermission,
  Loading,
  TargetStatus,
  TopBackArea
} from '../../../../components';
import { findFinancialContract } from '../../../../services/contractService';
import { GENERIC_ERROR } from '../../../../utils/constantes';
import { CoinName } from '../../../../utils/enums';
import ResizedLayout from '../../../../components/ResizedLayout';

export function DetailsFinancialContractPage() {
  const navigate = useNavigate();

  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const [loading, setLoading] = useState(true);
  const [financialContract, setFinancialContract] = useState({});
  const [contract, setContract] = useState({});
  const [error, setError] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  const breadcrumbs = [
    { title: 'Contratos', functionCoordinator: () => goToListAllContracts(navigate) },
    { title: 'Detalhes', functionCoordinator: () => goToDatailsContract(navigate, uuid) },
    { title: 'Financeiro' }
  ];

  const [convertCoin, setConvertCoin] = useState({ initialValue: false, currentValue: false });

  useEffect(async () => {
    await getFinancialContract();
  }, []);

  const getFinancialContract = async () => {
    try {
      const result = await findFinancialContract(uuid);
      setFinancialContract(result);
      setContract(result.contract);

      setLoading(false);
    } catch (err) {
      setError({
        title: err.message || GENERIC_ERROR,
        message: err.trace && `Código: ${err.trace}`
      });
      setLoading(false);
    }
  };

  const onConvertCoin = (field) =>
    setConvertCoin((prevValue) => ({ ...prevValue, [field]: !convertCoin[field] }));

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TopBackArea onBack={() => goToBack(navigate)} />
      <Loading loading={loading} />

      <Grid container>
        <Grid item xs={12}>
          <AlertMessage title={error.title} message={error.message} severity={error.severity} />
        </Grid>
      </Grid>

      <ResizedLayout.Container>
        <ResizedLayout.Card desktopSize="small" mobileSize="medium" width={3}>
          <ResizedLayout.Title>Informações</ResizedLayout.Title>
          <ResizedLayout.Item>
            <p>Título</p>
            <p>
              <Title>
                {contract.title?.toTitleCase() || '-'}
                <span>
                  <TargetStatus status={contract.status} variant="solid" />
                </span>
              </Title>
            </p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={6}>
            <p>Identificador</p>
            <p>{contract.id || '-'}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={6}>
            <p>É confidencial?</p>
            <p>{contract.isConfidential ? 'Sim' : 'Não'}</p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
        <ResizedLayout.Card desktopSize="small" mobileSize="small" width={1}>
          <ResizedLayout.Title>Número da conta</ResizedLayout.Title>
          <ResizedLayout.Item>
            <p>Débito</p>
            <p>{financialContract?.debit}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={6}>
            <p>Crédito</p>
            <p>{financialContract?.credit}</p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
        <ResizedLayout.Card desktopSize="tiny" mobileSize="small" width={2}>
          <ResizedLayout.Title>Reajuste</ResizedLayout.Title>
          <ResizedLayout.Item lg={6}>
            <p>Periodicidade</p>
            <p>{convertReadjustmentPeriodicity(financialContract.readjustmentPeriodicity)}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={6}>
            <p>Mês</p>
            <p>
              {financialContract.readjustmentMonth &&
                convertReadjustmentMonth(financialContract.readjustmentMonth)}
            </p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
        <ResizedLayout.Card desktopSize="small" mobileSize="small" width={1}>
          <ResizedLayout.Title>Em caso de atraso</ResizedLayout.Title>
          <ResizedLayout.Item>
            <p>Multa por atraso</p>
            <p>{financialContract.lateFee?.toString().replace('.', ',') || 0}%</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item>
            <p>Juros por atraso</p>
            <p>{financialContract.lateInterest?.toString().replace('.', ',')}%</p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
        <ResizedLayout.Card desktopSize="small" mobileSize="small" width={1}>
          <ResizedLayout.Title>Multa rescisória</ResizedLayout.Title>
          <ResizedLayout.Item>
            <p>Valor</p>
            <p>{financialContract.salaryPenalty?.toString().replace('.', ',')}%</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item>
            <p>Dias</p>
            <p>
              {financialContract.rescissionFine ? `${financialContract.rescissionFine} dias` : '-'}
            </p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
        <ResizedLayout.Card desktopSize="tiny" mobileSize="medium" width={4}>
          <ResizedLayout.Title>Pagamento</ResizedLayout.Title>
          <ResizedLayout.Item lg={4}>
            <p>Periodicidade</p>
            <p>{convertReadjustmentPeriodicity(financialContract.paymentPeriodicity)}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={4}>
            <p>Parcelas totais</p>
            <p>{financialContract.totalInstallments}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={4}>
            <p>Parcelas pagas</p>
            <p>{financialContract.installmentsPaid}</p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
        <ResizedLayout.Card desktopSize="tiny" mobileSize="medium" width={3}>
          <ResizedLayout.Title>Moeda</ResizedLayout.Title>
          <ResizedLayout.Item lg={4}>
            <p>Nome</p>
            <p>{financialContract?.coin?.title?.toTitleCase()}</p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={4}>
            <p>Cotação inicial</p>
            <p>
              {convertCentsToWhole(
                financialContract.coinHistoryInitial?.exchangeInReal,
                financialContract?.coin?.title
              ) || '-'}
            </p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={4}>
            <p>Cotação atual</p>
            <p>
              {convertCentsToWhole(
                financialContract.coinHistoryCurrent?.exchangeInReal,
                financialContract?.coin?.title
              ) || '-'}
            </p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
        <ResizedLayout.Card desktopSize="tiny" mobileSize="small" width={3}>
          <ResizedLayout.Title>Valores</ResizedLayout.Title>
          <ResizedLayout.Item lg={6}>
            <p>
              Valor inicial
              {financialContract?.coin?.title !== CoinName.REAL && (
                <abbr title="Converter para um valor aproximado em real com a cotação inicial (apenas visualização)">
                  <ButtonIcon color="primary" onClick={() => onConvertCoin('initialValue')}>
                    <CurrencyExchangeIcon />
                  </ButtonIcon>
                </abbr>
              )}
            </p>
            <p>
              {convertCoin.initialValue
                ? convertCentsToWhole(
                    convertNewValueCoin(
                      financialContract.initialValue,
                      financialContract.coinHistoryInitial?.exchangeInReal
                    ),
                    'real'
                  )
                : convertCentsToWhole(
                    financialContract.initialValue,
                    financialContract?.coin?.title
                  )}
            </p>
          </ResizedLayout.Item>
          <ResizedLayout.Item lg={6}>
            <p>
              Valor atual
              {financialContract?.coin?.title !== CoinName.REAL && (
                <abbr title="Converter para um valor aproximado em real com a cotação atual (apenas visualização)">
                  <ButtonIcon color="primary" onClick={() => onConvertCoin('currentValue')}>
                    <CurrencyExchangeIcon />
                  </ButtonIcon>
                </abbr>
              )}
            </p>
            <p>
              {convertCoin.currentValue
                ? convertCentsToWhole(
                    convertNewValueCoin(
                      financialContract.currentValue,
                      financialContract.coinHistoryCurrent?.exchangeInReal
                    ),
                    'real'
                  )
                : convertCentsToWhole(
                    financialContract.currentValue,
                    financialContract?.coin?.title
                  )}
            </p>
          </ResizedLayout.Item>
        </ResizedLayout.Card>
      </ResizedLayout.Container>

      <ButtonGroup>
        <HiddenForPermission keys="FIND_CONTRACT">
          <ButtonPage
            Icon={ViewListIcon}
            onClick={() => goToDatailsContract(navigate, contract?.uuid)}
          >
            Informações básicas
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys={['LIST_ADDITIONALS_CONTRACT', 'CREATE_ADDITIONAL_CONTRACT']}>
          <ButtonPage
            Icon={AddToPhotosIcon}
            onClick={() => goToListAdicionalsContract(navigate, contract?.uuid)}
          >
            Contratos adicionais
          </ButtonPage>
        </HiddenForPermission>
        <HiddenForPermission keys="FIND_FILE_CONTRACT">
          <ButtonPage
            Icon={InsertDriveFileIcon}
            onClick={() => goToVisualizeContract(navigate, contract?.uuid)}
          >
            Visualizar contrato
          </ButtonPage>
        </HiddenForPermission>
      </ButtonGroup>
    </BasePage>
  );
}
