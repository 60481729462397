import styled from 'styled-components';
import { colors, metrics } from '../../../theme/styles';
import { Grid } from '@mui/material';
import { breakpoints } from '../../../theme/breakpoints';

export const Container = styled(Grid)`
  margin-top: 1.2rem;
  padding-right: 0.5rem;

  p:first-child {
    font-weight: ${metrics.weight.regular};
    font-size: 1rem;
    color: ${colors.theme.secondary};
  }

  p:last-child {
    font-weight: ${metrics.weight.regular};
    font-size: 1.1rem;

    @media (min-width: ${breakpoints.md}) {
      font-size: 1.3rem;
    }
  }
`;
