import Router from './router/Routes';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { theme } from './theme';

import 'dayjs/locale/pt-br';
import './utils/prototypes';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <Router />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
