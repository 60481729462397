import { CardContent, Grid, IconButton } from '@mui/material';
import AutocompleteSearch from '../AutocompleteSearch';
import { Header, Title, Card } from './styled';
import ClearIcon from '@mui/icons-material/Clear';

function CityCard({
  city,
  index,
  isDesktopView,
  handleChange,
  removeCity,
  getStates,
  getCities,
  quantityItems
}) {
  return (
    <Card variant="outlined">
      <CardContent>
        <Header>
          <Title>Cidade {index + 1}</Title>
          {quantityItems > 1 && (
            <abbr title="Remover cidade">
              <IconButton color="error" onClick={() => removeCity(index)}>
                <ClearIcon />
              </IconButton>
            </abbr>
          )}
        </Header>
        <Grid container columnSpacing={isDesktopView ? 2 : 0}>
          <Grid item xs={12} lg={6}>
            <AutocompleteSearch
              label="Estado"
              name="uuid_state"
              value={city?.uuid_state || ''}
              onChange={(event) => handleChange(event, index)}
              optionLabel={['name']}
              getListDataCallback={getStates}
              formatLabel={(label) => label?.toCapitalizeCase?.()}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <AutocompleteSearch
              label="Cidade"
              name="uuid_city"
              value={city?.uuid_city || ''}
              disabled={!city?.uuid_state}
              onChange={(event) => handleChange(event, index)}
              optionLabel={['name']}
              getListDataCallback={() => getCities(city?.uuid_state)}
              formatLabel={(label) => label?.toCapitalizeCase?.()}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CityCard;
