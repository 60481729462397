import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  goToAdmin,
  goToBack,
  goToChoicePermission,
  goToConcludedRegisterUser,
  goToUsersList
} from '../../../../../../../router/coordinator';
import { BasePage } from '../../../../../../../components/BaseMain';
import { AlertMessage, Loading, Modal, TitlePages } from '../../../../../../../components';
import { Button, Grid, Switch } from '@mui/material';
import { Content } from './styled';
import { GENERIC_ERROR } from '../../../../../../../utils/constantes';
import {
  listAccessCompaniesNoPaginate,
  permissionAccessToCompany,
  setItemLocalStorage
} from '../../../../../../../services';
import { CustomFormControlLabel } from '../../../../../../../components/CustomFormControlLabel';
import { clearAlertMessage } from '../../../../../../../utils';

export function ChoiceCompanyPermiossionPage() {
  const [companiesChoice, setCompaniesChoice] = useState({});
  const [allCompaniesSelected, setAllCompaniesSelected] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    title: '',
    message: '',
    severity: 'error'
  });

  // --- Lógica do modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const onConfirmModal = () => {
    nextStep();
  };
  // ---

  const navigate = useNavigate();
  const { uuid } = useParams();
  if (!uuid) {
    goToBack(navigate);
  }

  const breadcrumbs = [
    { title: 'Administrativo', functionCoordinator: () => goToAdmin(navigate) },
    { title: 'Usuários', functionCoordinator: () => goToUsersList(navigate) },
    { title: 'Criar' },
    { title: 'Acesso as empresas' }
  ];

  useEffect(async () => await init(), []);

  const init = async () => {
    try {
      setLoading(true);

      const result = await listAccessCompaniesNoPaginate(true);
      setCompanies(result);

      initListOfCompanies(result);

      setLoading(false);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  /**
   * Cria o objeto no estado com todos os ids das
   * empresas e o valor se ela foi escolhida ou não
   */
  const initListOfCompanies = (companies) => {
    let companiesList = {};
    companies?.forEach((company) => {
      companiesList = { ...companiesList, [company.uuid]: false };
    });

    setCompaniesChoice(companiesList);
  };

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setCompaniesChoice({ ...companiesChoice, [name]: checked });
    setAllCompaniesSelected(null);
  };

  function RenderListCompanies({ list }) {
    return list.map((company) => {
      return (
        <CustomFormControlLabel
          key={company.uuid}
          control={
            <Switch
              checked={companiesChoice[company.uuid]}
              name={company.uuid}
              onChange={handleToggle}
            />
          }
          label={company.name.toTitleCase()}
        />
      );
    });
  }

  /**
   * A lista de empresas é divida em duas colunas
   * 1° coluna
   */
  function ListCompaniesFirthColumn() {
    const halfListLength = Math.round(companies.length / 2);
    const list = companies.slice(0, halfListLength);

    return <RenderListCompanies list={list} />;
  }

  /**
   * A lista de empresas é divida em duas colunas
   * 2° coluna
   */
  function ListCompaniesSecondColumn() {
    const halfListLength = Math.round(companies.length / 2);
    const list = companies.slice(halfListLength);

    return <RenderListCompanies list={list} />;
  }

  /**
   * Marcar ou desmarca todos o toggles
   * @param {*} value - verdadeiro ou falso
   */
  const changeAllToggles = (value) => {
    let newValues = {};
    for (const key in companiesChoice) {
      newValues = { ...newValues, [key]: value };
    }

    setCompaniesChoice(newValues);
    setAllCompaniesSelected(value);
  };

  const nextStep = async () => {
    try {
      setLoading(true);
      handleClose();

      await permissionAccessToCompany(uuid, companiesChoice);

      const companiesAccess = [];
      for (const key in companiesChoice) {
        if (companiesChoice[key]) {
          companiesAccess.push(key);
        }
      }

      if (!companiesAccess.length) {
        goToConcludedRegisterUser(navigate);
        return;
      }

      setItemLocalStorage('companies_access', companiesAccess);

      setLoading(false);
      goToChoicePermission(navigate, uuid);
    } catch (error) {
      setAlertMessage({ title: error.message || GENERIC_ERROR });
      setLoading(false);
    }
  };

  return (
    <BasePage navigate={navigate} breadcrumbs={breadcrumbs}>
      <TitlePages title={'Escolha as empresas que esse usuário terá acesso'} toHideDivider={true} />

      <Loading loading={loading} />

      <Grid container>
        <AlertMessage
          title={alertMessage.title}
          message={alertMessage.message}
          severity={alertMessage.severity}
          onClose={() => clearAlertMessage(setAlertMessage)}
        />
      </Grid>

      <Content container>
        <div id="button-group">
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => changeAllToggles(true)}
            disabled={allCompaniesSelected}
          >
            Marcar todas as empresas
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => changeAllToggles(false)}
            disabled={allCompaniesSelected === false}
          >
            Desmarcar todas as empresas
          </Button>
        </div>

        <Grid item className="column" xs={12} lg={6}>
          <ListCompaniesFirthColumn />
        </Grid>

        <Grid item className="column" xs={12} lg={6}>
          <ListCompaniesSecondColumn />
        </Grid>

        <Grid container className={'buttonGroup oneButton'}>
          <Button onClick={handleOpen}>Salvar e avançar</Button>
        </Grid>
      </Content>

      <Modal
        open={openModal}
        onClose={handleClose}
        onConfirm={onConfirmModal}
        title={'Adicionar permissões de acesso ao usuário'}
        text={`Tem certeza que deseja dar permissão de acesso dessas empresas ao usuário? `}
        warning={'Ao confirmar, as permissões de acesso já serão atribuidas  ao usuário'}
        buttonConfirm={'Sim'}
        buttonCancel={'Não'}
      />
    </BasePage>
  );
}
