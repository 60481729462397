import { Card as CardMUI } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../theme/styles';
import { breakpoints } from '../../../theme/breakpoints';

export const Card = styled(CardMUI)`
  margin: 0.8rem 0.2rem;
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid ${colors.gray}3d;

  && {
    background-color: ${colors.backgroundWhite};
  }

  grid-row-end: ${({ $mobile }) => {
    if ($mobile === 'small') {
      return 'span 26';
    } else if ($mobile === 'medium') {
      return 'span 38';
    } else if ($mobile === 'large') {
      return 'span 50';
    }

    return 'span 50';
  }};
  grid-column-end: 1;
  min-height: 6.25rem;

  @media (min-width: ${breakpoints.md}) {
    grid-column-end: ${({ $width }) => `span ${$width || 1}`};

    grid-row-end: ${({ $desktop }) => {
      if ($desktop === 'tiny') {
        return 'span 17';
      } else if ($desktop === 'small') {
        return 'span 26';
      } else if ($desktop === 'medium') {
        return 'span 34';
      } else if ($desktop === 'large') {
        return 'span 45';
      }

      return 'span 45';
    }};
  }
`;
