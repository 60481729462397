import styled from 'styled-components';
import { colors, metrics } from '../../../theme/styles';
import { Grid, Link as LinkMUI } from '@mui/material';

export const Container = styled(Grid)`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.8rem;

  h3 {
    color: ${colors.theme.primary};
    font-weight: ${metrics.weight.medium};
  }
`;

export const Link = styled(LinkMUI)`
  && {
    font-size: 0.8rem;
  }
`;
